/* Custom styles for MUI Pagination */
/* .MuiPaginationItem-root.Mui-selected {
  background-color: #5a4ad5 !important;
}

.MuiPaginationItem-root.Mui-selected:hover {
  background-color: #4a3db3 !important;
}

.MuiPaginationItem-root {
  color: #2c2d3a;
}

.css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
  color: #4a3db3 !important;
  font-weight: 600 !important;
  font-size: 30px !important;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
} */
.pagination {
  --bs-pagination-padding-x: 0.55rem;
  --bs-pagination-padding-y: 0.375rem;
  /* --bs-pagination-font-size: 1rem; */
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: unset;
  --bs-pagination-border-color: var(--bs-border-color);
  /* --bs-pagination-border-radius: var(--bs-border-radius); */
  /* --bs-pagination-hover-color: var(--bs-link-hover-color); */
  /* --bs-pagination-hover-bg: var(--bs-tertiary-bg); */
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: unset;
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.pagination .page-link {
  color: var(--dark-gray);
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  background-color: transparent;

}

.pagination .page-link:hover {
  color: var(--primary-second);
  /* Maintain blue on hover */
  text-decoration: none;
}

.pagination .page-item.active .page-link {
  background-color: transparent;
  border: none;
  color: var(--primary-second);
  font-weight: bold;
  /* text-decoration: underline; */
}
.pagination  .page-item.active {
  border-bottom: 3px solid var(--primary-second);
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  /* Bootstrap default gray for disabled items */
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  /* background-color: var(--bs-pagination-focus-bg); */
  outline: 0;
  box-shadow: unset;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.page-item {
  cursor: pointer;
}

.page-item.disabled {
  pointer-events: none;
  color: #ccc;
}

.page-item .page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  /* border: 1px solid #ddd; */
  /* border-radius: 50%; */
  font-size: 14px;
  color: #333;
  text-decoration: none;
  background: #fff;
  transition: all 0.2s ease;
}

.page-item .page-link:hover {
  background: #e0e0e0;
}

.page-item.active .page-link {
  background: #0056b3;
  color: #fff;
  border-color: #0056b3;
}

.page-link {
  text-align: center;
}

.page-link svg {
  font-size: 20px;
  color: #0056b3;
}
