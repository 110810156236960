/* fund section  */

.start_investment_box {
  background-color: #f5f6ff !important;
  border-radius: 12px !important;
  padding: 20px 20px;
  margin-bottom: 30px;
  box-shadow: none !important;
  border: 1px solid #ededed;
}

.headerBackground {
  background-color: #f0f4ff;
}
.start_investment_box .investment-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.gross_percent {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

.start_investment_box .investment-value-header {
  /* font-family: 'Lato'; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #565a71 !important;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.start_investment_box .value-text {
  /* font-family: Lato; */
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #565a71    ;
  letter-spacing: 1px;
}
.start_investment_box .page-title {
  /* font-family: Lato; */
  font-size: 20px;
  font-weight: 700;
  line-height: 38.2px;
  text-align: left;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

@media (min-width: 425px) {
}

@media (min-width: 576px) {
  .start_investment_box {
    padding: 28px 28px;
    margin-bottom: 35px;
  }
  .start_investment_box .investment-info {
    gap: 28px;
  }
  .start_investment_box .page-title {
    font-size: 20px;
    line-height: 40.2px;
    margin-bottom: 28px;
  }
}

@media (min-width: 768px) {
  .start_investment_box {
    padding: 32px 32px;
    margin-bottom: 35px;
  }
  .start_investment_box .investment-info {
    gap: 35px;
  }
  .start_investment_box .value-text {
    font-size: 22px;
    line-height: 30px;
  }
  .start_investment_box .investment-value-header {
    margin-bottom: 6px;
  }
}

@media (min-width: 992px) {
  .start_investment_box .value-text {
    font-size: 24px;
  }
  .start_investment_box .investment-info {
    gap: 50px;
  }
  .start_investment_box .page-title {
    font-size: 23px;
    margin-bottom: 35px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
  .start_investment_box .investment-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 180px;
  }
  .start_investment_box {
    padding: 34px 34px;
    margin-bottom: 35px;
  }
}
@media (max-width: 600px) {
  .investment-value-header {
    font-size: 12px; /* Smaller font size on mobile */
  }

  .value-text {
    font-size: 14px; /* Adjust value text font size */
  }

  .page-title {
    font-size: 16px; /* Smaller page title */
  }
}

@media (min-width: 601px) {
  .investment-value-header {
    font-size: 14px; /* Default font size */
  }

  .value-text {
    font-size: 16px; /* Default value text font size */
  }

  .page-title {
    font-size: 20px; /* Default page title size */
  }
}
