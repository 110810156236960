.investcard {
  background-color: rgba(245, 247, 255, 1);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 1);
}

.investemnt-card-head {
  font-size: 24px !important;
  font-weight: 600 !important;
  /* font-family: "Poppins" !important; */
  color: rgba(11, 36, 194, 1) !important;
}

.investemnt-card-head2 {
  font-size: 28px !important;
  /* font-family: "Poppins" !important; */
  font-weight: 600 !important;
}

.investemnt-card-head2-span {
  color: rgb(62 65 84) !important;
}

.investemnt-card-totalreturnhead {
  font-size: 28px !important;
  /* color: rgba(70, 160, 76, 1) !important; */
  font-weight: 600 !important;
}
.negative {
  color: rgba(224, 69, 35, 1) !important;
}

.positive {
  color: rgba(70, 160, 76, 1) !important;
}

.hashboard-na {
  color: rgba(224, 69, 35, 1) !important;
}

.investment-1dreturn {
  font-size: 28px !important;
  font-weight: 600 !important;
  /* color: rgba(224, 69, 35, 1) !important; */
}

.drop-icon {
  color: rgba(11, 36, 194, 1);
  font-size: 32px !important;
}

.accordianbg {
  background-color: #f5f7fb;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
}

.font-600 {
  font-weight: 600 !important;
}

.sip-tabs .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgba(11, 36, 194, 1) !important;
  text-transform: capitalize !important;
}

.sip-tabs .css-1ujykiq-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
}

.sip-aamount-box {
  background-color: rgba(233, 236, 255, 1);
  color: rgba(11, 36, 194, 1);
  border-radius: 4px;
  padding: 5px;
  width: 100px;
}

.sip-aamount-box2 {
  background-color: rgba(233, 236, 255, 1);
  color: rgba(11, 36, 194, 1);
  border-radius: 4px;
  padding: 5px !important;
  width: 100px !important;
}

.add-cartbtn {
  text-transform: capitalize !important;
  background-color: rgba(233, 236, 255, 1) !important;
  color: rgba(11, 36, 194, 1) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.start-sipbtn {
  text-transform: capitalize !important;
  background-color: rgba(11, 36, 194, 1) !important;
  color: white !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
}

.confirmbtn {
  background-color: rgba(233, 233, 235, 1) !important;
  color: gray !important;
  text-transform: capitalize !important;
}

.resend-link {
  cursor: pointer;
  text-decoration: underline;
}

.pan-card-margin {
  margin-top: 60px;
}

.explore-margin {
  margin-top: 60px;
}

.explore-card-margin {
  margin-bottom: -40px;
}

.birla-cardimg {
  height: 40px;
}

.redeem-form-check .css-j204z7-MuiFormControlLabel-root {
  margin-right: 0px !important;
}

@media (max-width: 600px) {
  .investemnt-card-head {
    font-size: 20px !important;
  }

  .investemnt-card-head2 {
    font-size: 22px !important;
  }

  .investemnt-card-totalreturnhead {
    font-size: 22px !important;
  }

  .investment-1dreturn {
    font-size: 22px !important;
  }

  .pan-card-margin {
    margin-top: 0px;
  }

  .back-bread {
    margin-top: 22px;
  }

  .swp-card-mar {
    margin-top: 10px;
  }
}

/* Medium devices (landscape tablets, 768px and down) */
/* @media (max-width: 768px) {
    .pan-card-margin {
        margin-top: 40px !important;
    }
} */

/* *******************************view transaction********************* */

.trans-cardpad {
  padding: 0px 30px 0px 30px;
}

.card-wrap p {
  text-wrap: nowrap;
}

.rs-fontsize {
  font-size: 1.5rem !important;
}

.font-12 {
  font-size: 12px !important;
}

.skip-popup-card {
  border-radius: 10px !important;
  text-align: center !important;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Button styles */
.toggle-button {
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 12px !important;
  font-weight: bold;
  text-transform: none;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

/* Active Button Style */
.toggle-button.active {
  background-color: #dfe6fd;
  color: rgba(11, 36, 194, 1);
  border: none;
  border-radius: 30px;
  margin-left: 10px;
}

/* Inactive Button Style */
.toggle-button.inactive {
  background-color: #fff;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-left: 10px;
}

/* Hover Effect */
.toggle-button:hover {
  background-color: #f9f9f9;
}

.font-14 {
  font-size: 14px !important;
}

.popup-calender-border {
  border: 1px solid rgba(237, 237, 237, 1);
  padding: 10px;
  border-radius: 10px;
}

.calender-btn-popup {
  border-radius: 50% !important;
  min-height: 30px !important;
  min-width: 30px !important;
  padding: 0 !important;
  border: none !important;
}

/* styles.css */

.popup-calender-border {
  padding: 16px;
  /* Adjust as needed */
  border: 1px solid #ccc;
  /* Optional: Add border around the calendar */
  border-radius: 8px;
  /* Optional: Add border radius */
}

.calender-btn-popup {
  border-radius: 50%;
  /* Make buttons round */
  min-width: 40px;
  /* Minimum width of buttons */
  min-height: 40px;
  /* Minimum height of buttons */
  padding: 0;
  /* Remove default padding */
  font-weight: bold;
  /* Optional for better visibility */
  transition: background-color 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

/* Active date button styles */
.calender-btn-popup.active {
  background-color: rgba(11, 36, 194, 1);
  /* border-radius: 8px !important; */
  /* Blue background for active date */
  color: white;
  /* White text color for active date */
}

/* Inactive date button styles */
.calender-btn-popup.inactive {
  background-color: transparent;
  /* border-radius: 8px !important; */
  /* Gray background for inactive dates */
  border: 2px solid #ccc;
  /* Optional: border for inactive dates */
  color: rgba(60, 61, 79, 1);
  /* Black text color for inactive dates */
}

/* Hover effects for buttons */
.calender-btn-popup:hover {
  opacity: 0.8;
  /* Slightly darken on hover */
}

.calender-btn-popup.inactive:hover {
  background-color: #d0d0d0;
  /* Darker gray on hover for inactive */
}

.calender-btn-popup.active:hover {
  background-color: rgb(63, 87, 244);
  color: white;
}

.list-border {
  border: 1px solid rgba(237, 237, 237, 1) !important;
  margin-bottom: 10px !important;
  border-radius: 10px !important;
}

.cancel-sip-popup .MuiListItemText-primary {
  color: rgba(62, 65, 84, 1) !important;
}

/* Icon color for all ListItemIcon components */
.cancel-sip-popup .MuiListItemIcon-root {
  color: rgba(62, 65, 84, 1) !important;
}

.continue-investingbtn {
  color: rgba(11, 36, 194, 1) !important;
  text-transform: capitalize !important;
}

.cancel-sip-popup p {
  color: rgba(62, 65, 84, 1) !important;
}

.grow-card-custom {
  padding: 13px 22px;
}

@media only screen and (max-width: 600px) {
  .start-stp-container {
    flex-direction: column;
  }

  .start-stp-img {
    width: 200px;
    margin-right: 110px;
    margin-bottom: 30px;
  }
}
.page_background {
  padding: 20px;
}

.page_background .page-inner-content {
  border-radius: 12px;
  overflow: hidden;
}

.page_background .page_inner_content_box {
  /* background-color: var(--light-gray); */
  padding: 25px;
  border: 1px solid #ededed;
  border-radius: 15px;
  /* height: 100vh;
  overflow-y: auto;
  scrollbar-width: none; */
}

.page_background .page_content_overflow {
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
}
.view_all_btn {
  color: #0a23c1;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}
@media (max-width: 400px) {
  .view_all_btn {
    font-size: 14px;
  }
}
