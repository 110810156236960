.mycard-header.MuiTypography-root {
  font-weight: 600;
  font-size: 20px;
  color: var(--h-color);
}

.mycard-icon {
  margin: 0;
}

.mycard-icon img {
  width: 37px;
  height: 37px;
  background-color: #ededed;
  border-radius: 8px;
}

.mycard-title-text {
  font-weight: 500;
  color: var(--h-color) !important;
  margin: 0;
  font-size: 18px;
}

.grid-container {
  padding: 16px;
  /* font-family: "Roboto", sans-serif; */
}

.amount-section {
  margin-top: 20px !important;
  gap: 10px;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 16px !important;
}

.mycard-amount.MuiTypography-root {
  font-weight: 500;
  color: var(--h-color) !important;
  margin: 0;
  font-size: 18px;
}

.mycard-description.MuiTypography-root {
  color: var(--light-font-color);
}

.edit-icon {
  cursor: pointer;
  color: var(--light-font-color);
}

.grid-item {
  margin-bottom: 8px;
}

.label {
  font-size: 14px;
  color: gray;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

/* ---------- */

.payment-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.amount-container {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.amount_text {
  font-weight: 600 !important;
  color: var(--h-color) !important;
  /* color: var(--light-font-color); */
}

.amount_label {
  color: var(--h-color) !important;
  margin-top: 4px !important;
}

.terms-section {
  text-align: center;
  margin-bottom: 24px;
  /* color: var(--h-color); */
}

.terms-section p {
  color: var(--h-color);
}

.terms-link {
  color: #0b24c2 !important;
  text-decoration: none !important;
}

.payment-methods {
  margin-bottom: 24px !important;
}

.payment-method-item {
  margin: 0;
}

.method-icon {
  color: #666666;
}

.method-text span {
  font-size: 14px;
  color: #1a1a1a;
}

.Proceedtoay-btn {
  padding: 12px !important;
  text-transform: capitalize !important;
  background-color: rgba(11, 36, 194, 1) !important;
  color: white !important;
  border-radius: 10px !important;
  border-radius: 8px !important;
}

.watchlist_search_field .MuiInputBase-root {
  height: 45px !important;
  border-radius: none !important;
}

.watchlist_search_field svg {
  margin: 10px;
}

.morefunds-btn {
  text-transform: capitalize !important;
  color: #0b24c2 !important;
  margin-top: 30px !important;
  border-radius: 8px !important;
}

.morefunds-btn svg {
  color: #0b24c2 !important;
}

.confirmbtn-mycart.MuiButton-root {
  border-radius: 8px;
  padding: 12px;
  background-color: rgba(233, 233, 235, 1);
  color: gray;
  text-transform: capitalize;
}

.custom-text-field .MuiInputBase-root {
  transition: border-color 0.2s ease-in-out;
}

.custom-text-field .MuiInputBase-root:hover fieldset {
  border-color: #ededed !important;
}

.custom-text-field .MuiInputBase-root.Mui-focused fieldset {
  border-color: #ededed !important;
  box-shadow: none;
}

.custom-text-field .css-953pxc-MuiInputBase-root-MuiInput-root::before {
  border-bottom: none !important;
}

.custom-text-field-child-1 {
  border-top: 1px solid #e0e0e0;
  /* border-bottom: 1px solid #e0e0e0; */
}

.custom-text-field .MuiInputBase-root {
  border-radius: 0;
  /* Removes border radius */
}

.receptbox-pad {
  padding: 22px 22px 0px 22px;
}

.cart-nowrap {
  white-space: nowrap !important;
}

.my-cart-calendar {
  width: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 8px;
  z-index: 9;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
  .my-cart-calendar {
    right: -30px;
    left: auto;
  }
}

/* ---------- */
