.FAQAdd_button {
    background-color: #0a23c1 !important;
    border-radius: 20px !important;
    width: 100px;
}
.FAQAdd_button1 {
    background-color: #0a23c1 !important;
    border-radius: 10px !important;
    width: 100px;
    height: 50px;
}

.main-heading-title {
    font-size: 35px !important;
    font-weight: 500 !important;
}

.page_inner_content_box {
    border: 1px solid #ededed;
    /* margin: 20px; */
    border-radius: 10px;
}

.page-inner-content {
    /* padding: 20px; */
}

.transaction_tab {
    margin-top: 20px !important;
}

.table-text {
    /* font-weight: 600 !important; */
}

.edit-icon {
    background-color: #dfdbff !important;
    border-radius: 5px !important;
}

.delete-icon {
    background-color: #ffdcdc !important;
    border-radius: 5px !important;
}

.faq-icon {
    gap: 10px;
}

.add-faqs-input {
    height: 46px;
    width: 100% !important;
}

.answer-input {
    /* margin-top: 30px !important; */
}

/* Password css */
.password_button{
    background-color: #0a23c1 !important;
    border-radius: 10px !important;
    width: 200px;
    height: 50px;
}

.password_box{
    width: 60%;
    margin: auto;
}

@media (max-width: 1200px) {
    .faqs-fields {
        display: grid !important;
        grid-template-columns: 1fr; /* Stack items vertically */
    }
    .css-1b3l6lk-MuiGrid-root{
        min-width: 100% !important;
    }
}

@media (max-width: 770px) {
    .page-inner-content {
        padding: 0px;
    }

    .page_inner_content_box {
        margin: 0px;
    }

    .main-heading-title {
        font-size: 15px !important;
    }
}

@media (max-width: 700px) {
    .table-text {
        font-size: 15px !important;
    }
}

@media (max-width: 850px) {
    .add-faqs-input {
        width: 100% !important;
    }

    .answer-input {
        /* margin-top: 30px !important; */
    }

    .page_inner_content_box {
        margin: 0px;
    }

    .password_box{
        width: auto;
    }
}
