.customer_select{
    margin-left: 0 !important;
}

.customer_date .css-10o2lyd-MuiStack-root{
    padding-top:  0;
}

.customer_date input{
    padding: 10px;
    font-size: 13px;
}

.customer_date .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
}

/* Investment CSS */

.invesment_space{
    margin-left: 0 !important;
}

.investment_search{
    width: auto !important;
}