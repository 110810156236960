.pin-setup-container {
  /* min-height: 100vh; */
  background-color: #f8f9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.pin-setup-header {
  margin-bottom: 60px;
}

.pin-card {
  background: white;
  max-width: 440px;
  width: 100%;
  border-radius: 16px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05) !important;
}

/* .card-content {
  padding: 2rem !important;
  display: flex;
  flex-direction: column;

  gap: 0px;
} */

.title {
  color: rgba(62, 65, 84, 1);
  font-weight: 600 !important;
  font-size: 24px !important;
  margin-bottom: 0.5rem !important;
}

.subtitle {
  text-align: center;
  font-size: 14px !important;
  margin-bottom: 1rem !important;
  font-weight: 400 !important;
}

.pin-input-container {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 2rem 0;
  align-items: center;
  justify-content: center;
}

.pin-input {
  width: 40px !important;
}

.pin-input input {
  font-size: 1.5rem;
  text-align: center;
  color: #1a1a1a;
}

/* Style for the underline */
.pin-input .MuiInput-underline:before {
  border-bottom: 2px solid #e5e7eb !important;
}

.pin-input .MuiInput-underline:after {
  border-bottom: 2px solid #0b24c2 !important;
}

.submit-button {
  background-color: #0b24c2 !important;
  color: white !important;
  padding: 0.75rem 2rem !important;
  border-radius: 8px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  min-width: 200px !important;
  margin: 1rem 0 !important;
}

.footer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.email {
  font-size: 15px;
  font-weight: 400;
}

.logout-link {
  color: #0b24c2;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
}

.logout-link:hover {
  text-decoration: underline;
}

/* -------------------- */

.bank-selection-container {
  /* min-height: 100vh; */
  background-color: #f8f9ff;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.bank-selection-card {
  background: white;
  max-width: 550px;
  width: 100%;
  border-radius: 16px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05) !important;
}

/* .card-content {
  padding: 1.5rem !important;
} */

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.back-button {
  padding: 8px !important;
}

.back-button-kyc {
  color: #3e4154 !important;
  margin-bottom: 10px !important;
}

.header-text {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  color: #1a1a1a;
}

.search-field {
  margin-bottom: 1.5rem !important;
}

.search-field .MuiOutlinedInput-root {
  background-color: #f8f9ff;
  border-radius: 8px;
}

.search-icon {
  color: #666666;
}

.banks-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.bank-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.bank-item:hover {
  border-color: #1939ff;
  background-color: #f8f9ff;
}

.bank-logo-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.bank-name {
  font-size: 0.9rem !important;
  color: #1a1a1a;
}

.more-banks-title {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #1a1a1a;
  margin-bottom: 1rem !important;
}

.more-banks-list {
  padding: 0 !important;
}

.more-bank-item {
  /* padding: 0.75rem !important; */
  /* border: 1px solid #e5e7eb; */
  border-radius: 8px;
  margin-bottom: 0.5rem !important;
  cursor: pointer;
  transition: all 0.2s ease;
}

.more-bank-item:hover {
  border-color: #1939ff;
  background-color: #f8f9ff;
}

.more-bank-logo-container {
  min-width: 32px !important;
  width: 32px;
  height: 32px;
}

.more-bank-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Styles for LinkedAccountCard component  Start*/

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.success-icon {
  margin-bottom: 1.5rem !important;
  color: #0b24c2 !important;
}

.all-set-icon {
  margin-bottom: 1.5rem !important;
  color: #0b24c2 !important;
  font-size: 110px !important;
}

.success-title {
  color: #3e4154 !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.success-message {
  color: #3e4154 !important;
}

/* Styles for LinkedAccountCard component  End*/

/* BOB component  Start */

/* .dob-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
}  */

.bob-selection-card {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.bob-card-content {
  flex-grow: 1 !important;
  padding: 16px !important;
}

.bob-card-actions {
  padding: 16px !important;
  margin-top: 250px;
}

.back-button {
  margin-bottom: 24px;
}

.form-title {
  margin-bottom: 8px !important;
  font-size: 20px !important;
  color: rgba(62, 65, 84, 1) !important;
  font-weight: bold !important;
}

.form-subtitle {
  color: rgba(62, 65, 84, 1) !important;
  /* margin-bottom: 24px !important; */
}

.date-picker-wrapper {
  margin-bottom: 24px;
  width: 100%;
}

.trading-note {
  color: rgba(62, 65, 84, 1) !important;
  font-size: 15px !important;
  margin: 24px 0;
  font-weight: 500 !important;
}

.next-button {
  width: 100% !important;
  background-color: #c4c4c4;
  color: #a3a3a3;
  font-weight: bold !important;
  padding: 12px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.next-button:disabled {
  background-color: #e9e9eb;
  color: #666;
}

/* BOB component  End*/

/* .verify-location-box {
} */

.verify-location-img {
  width: 166px;
}

.trading-note-text {
  color: #666 !important;
  font-size: 0.875rem !important;
  margin: 24px 0 !important;
  text-align: center !important;
}

/* ---------------------- */

.open-camera {
  width: 100% !important;
  background-color: #0b24c2 !important;
  color: #fff !important;
  font-weight: bold !important;
  padding: 12px !important;
  text-transform: none !important;
  border-radius: 8px !important;
}

.kyc-card-actions {
  padding: 16px !important;
  margin-top: 80px;
}

/* ---------------------- */

/* ----------------- */

.signature-checkbox {
  color: #0b24c2 !important;
}

.signature-checkbox-text {
  color: #565a71 !important;
  font-size: 14px !important;
}

.signature-checkbox-mb {
  margin-bottom: 22px !important;
}

/* .signature-checkbox-text2 span {
  margin-top: 17px !important;
} */

.signature-reset {
  background-color: #e9ecff !important;
  color: #0b24c2 !important;
  font-weight: bold !important;
  padding: 12px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  width: 180px;
}

.signature-submit {
  background-color: #0b24c2 !important;
  color: #fff !important;
  font-weight: bold !important;
  padding: 12px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  width: 180px;
}

.nominee-mb-1 {
  margin-bottom: 10px !important;
}

/* ----------------- */

/* Noaminees Css  */

.nominees-container {
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 8px;
}

.nominees-title {
  color: rgba(62, 65, 84, 1);
  margin-bottom: 16px;
  font-size: 16px !important;
}

.nominees-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.list-item svg {
  color: #3e4154;
}

.list-item-text {
  color: #3e4154 !important;
  font-size: 16px !important;
}

.mb-nominee {
  margin-bottom: 30px !important;
}

.add-another-nominee {
  color: #0b24c2 !important;
  font-weight: bold !important;
  font-size: 17px !important;
  text-transform: none !important;
  margin-bottom: 5px !important;
}

.nominee-option {
  color: #565a71 !important;
}

.nominee-inputs-bg {
  background: #f5f5f5 !important;
}

.nominee-inputs-bg .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 20px !important;
  /* Removes border for outlined TextField */
}

/* Noaminees Css  */

/* Responsive adjustments */

@media (max-width: 600px) {
  .bank-selection-container {
    padding: 1rem;
  }

  .banks-grid {
    grid-template-columns: 1fr;
  }

  .signature-checkbox-mb {
    margin-bottom: 55px !important;
  }

  .pin-setup-header {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .checkbox-text-custom .MuiFormControlLabel-label {
    margin-top: 21px;
  }
}
