/* General Styles */
.help-support-container {
  padding: 24px;
  margin-top: 60px;
}

.help-support-title.MuiTypography-root {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--h-color);
}

.help-support-search .MuiOutlinedInput-root {
  height: 45px !important;
  width: 400px;
  margin-bottom: 24px;
}

.help-support-tabs {
  margin-bottom: 16px;
  color: #0b24c2;
}

.support-tabs-selected
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0b24c2;
}

.support-tabs-bg .css-1aquho2-MuiTabs-indicator {
  background-color: #0b24c2;
}

.help-support-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.help-support-sidebar {
  width: 100%;
  /* border-right: 1px solid #ccc; */
  padding: 16px;
}

.help-support-main {
  flex: 1;
  padding: 16px;
}

.help-support-list-item {
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.3s, font-weight 0.3s;
}

.help-support-list-item:hover,
.help-support-list-item.selected {
  background-color: #e9ecff;
  font-weight: 600;
  padding: 13px;
  color: var(--h-color);
}

.help-support-accordion-title.MuiTypography-root {
  font-size: 18px;
  font-weight: 600;
  color: var(--h-color);
}

.help-support-accordion-content.MuiTypography-root {
  font-size: 14px;
  color: var(--h-color);
}

.user-details-meun {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid rgba(237, 237, 237, 1);
  border-radius: 10px;
  /* height: 80vh;  */
  height: auto;
}

@media only screen and (max-width: 900px) {
  .user-details-meun {
    height: auto;
  }
}

@media (min-width: 768px) {
  .help-support-grid {
    grid-template-columns: 1fr 3fr;
  }

  .help-support-sidebar {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .help-support-search .MuiOutlinedInput-root {
    width: 100%;
  }
}
