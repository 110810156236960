.not-found-container {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* background-color: #f0f4ff; */
}

.oops-text-section {
  text-align: left;
  padding: 20px;
}

.oops-text {
  font-size: 3rem !important;
  font-weight: bold !important;
  color: #2c2c2c !important;
}

.not-found-message {
  font-size: 1.2rem !important;
  color: #555 !important;
  margin: 10px 0 !important;
}

.oops-back-button {
  background-color: #0b24c2 !important;
  color: white !important;
  padding: 10px 20px !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
}

.oops-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-image {
  width: 100%;
  max-width: 100%;
  height: 50%;
  margin-bottom: 30px;
  object-fit: cover;
}
