.chart-container {
  width: 100%;
  /* max-width: 800px; */
  /* margin: auto; */
  font-family: Arial, sans-serif;
}

.chart-title {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600 !important;
}

.chart-header {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.chart-percentage {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}

.chart-period {
  font-size: 14px;
  color: #666;
}

.chart-buttons {
  margin-bottom: 20px;
}

.chart-button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.chart-wrapper {
  width: 100%;
  height: 300px;
}

.chart-footer {
  display: flex;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  gap: 50px;
}

.custom-tooltip {
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
}

.mt-60 {
  margin-top: 60px;
}

.slider-container {
  max-width: 400px;
  /* margin: auto; */
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.slider-box {
  margin-bottom: 20px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.slider-wrapper {
  display: flex;
  align-items: center;
}

.slider {
  flex: 1;
  margin-right: 10px;
}

.value-box {
  width: 80px;
  text-align: center;
}

/* ------------ */

.accordian-funds-bg {
  background-color: rgba(245, 245, 245, 1);
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
}

.folio-box {
  margin-left: 26px;
  margin-bottom: 20px;
}

.folio-num {
  color: #3e4154 !important;
}

.folio-num span {
  font-weight: bold !important;
}

/* ------------ */

/* All Mutual Funds CSS Start */

.mf_table_container {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 8px !important;
}

.mf_table_root {
  min-width: 650px;
}

/* -------------- */

/* .mf_table_root {
  min-width: auto !important;
  overflow-x: unset !important;
}

.mf_tableContainer-root {
  min-width: auto !important;
  overflow-x: unset !important;
  width: 100% !important;
  table-layout: fixed !important;
}

@media (max-width: 600px) {
  .mf_table_root {
    display: block;
  }
} */

/* -------------- */

.mf_header_cell {
  color: #666 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  border-bottom: 2px solid #f0f0f0 !important;
  text-align: center !important;
}

.mf_scheme_header {
  display: flex !important;
  align-items: center !important;
}

.mf_scheme_header-icon {
  color: #666 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  border-bottom: 2px solid #f0f0f0 !important;
  text-align: center !important;
}

.mf_scheme_header-icon svg {
  color: #0b24c2 !important;
  font-size: 30px;
}

.mf_table_row:hover {
  background-color: #f8f9fa;
}

.mf_scheme_cell {
  padding: 16px !important;
}

.mf_scheme_content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mf_fund_logo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.mf_scheme_details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mf_scheme_name {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.mf_scheme_info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mf_risk_label,
.mf_type_label {
  color: #666;
  font-size: 12px;
}

.mf_dot {
  color: #666;
  font-size: 12px;
}

.mf_returns_cell {
  font-weight: 500 !important;
  color: #333 !important;
  font-size: 14px !important;
  text-align: center !important;
}

.mf_filters_container {
  width: 100%;
  max-width: 400px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.mf_filters_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.mf_filters_title {
  margin: 0;
  font-weight: 500;
}

.mf_clear_btn {
  background: none;
  border: none;
  color: #0b24c2;
  cursor: pointer;
  font-weight: 500;
}

.mf_accordion {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mf_accordion:before {
  display: none;
}

/* .mf_search_field {
  margin-bottom: 16px !important;
} */

.mf_search_field .MuiOutlinedInput-root {
  border-radius: 30px !important;
  height: 45px !important;
  margin-bottom: 16px !important;
}

.mf_checkbox_group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mf_checkbox_label {
  margin: 0 !important;
}

.mf_nested_accordion {
  /* margin: 0 !important; */
  margin-top: -20px !important;
  box-shadow: none !important;
}

.mf_nested_accordion:before {
  display: none;
}

.mf_nested_checkbox {
  padding-left: 32px;
}

.scrollable-list {
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto;
  padding-right: 8px; /* Space for the scrollbar */
}

/* Override MUI styles */
.mf_accordion .MuiAccordionSummary-root {
  min-height: 48px;
}

.mf_accordion .MuiAccordionDetails-root {
  padding: 8px 16px 16px;
}

.mf_nested_accordion .MuiAccordionSummary-root {
  padding: 0;
}

.mf_nested_accordion .MuiAccordionDetails-root {
  padding: 0;
}

.search-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-results-text {
  color: #3e4154 !important;
  font-size: 16px;
}

.search-count {
  font-weight: 500;
  color: #000000;
}

.sort-select-container {
  min-width: 200px;
}

/* .sort-select.MuiSelect-root {
  font-size: 14px !important;
  padding-right: 60px !important;
  color: #3e4154 !important;
} */

.sort-select
  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-right: 60px !important;
  color: #3e4154 !important;
  font-size: 16px !important;
}

.MuiSelect-icon {
  color: #3e4154 !important;
}

.mutual-header-text {
  color: #3e4154 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.mutual-summary-text {
  color: #3e4154 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  margin-left: 5px !important;
}

.mf_checkbox_group {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
  /* gap: 0px; */
}

.mf_nested_accordion .nested-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* Adjust spacing between checkboxes */
  padding-left: 16px;
  /* Add indentation for nested checkboxes */
}

.mf_checkbox_label {
  font-size: 14px;
  color: #333;
}

.mf_nested_checkbox {
  font-size: 14px;
  margin-left: 20px;
}

.mf_nested_checkbox-mt {
  margin-top: -16px;
}

/* ----------------- */

.receipt-wrapper {
  padding: 0px 25px;
  border-radius: 8px;
}

.receipt-wrapper-box {
  border: 1px solid #ededed !important;
  border-radius: 10px !important;
  padding: 22px;
}

.receipt-wrapper-box2 {
  border: 1px solid #ededed !important;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.oder-details-status {
  padding: 25px 30px 0px 30px;
}

.oder-card-margin {
  margin-top: 30px !important;
}

.scheme-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.scheme-icon {
  margin: 0;
}

.scheme-icon img {
  width: 60px;
  height: 60px;
  background-color: #007bff;
  border-radius: 8px;
}

.scheme-content {
  flex: 1;
}

.scheme-title {
  font-weight: 400 !important;
  color: rgba(62, 65, 84, 1) !important;
  font-family: "roboto" !important;
  margin: 0;
}

.scheme-subtitle {
  color: rgba(62, 65, 84, 1) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0;
}

.scheme-amount {
  font-weight: 600 !important;
  color: rgba(62, 65, 84, 1) !important;
  font-size: 18px !important;
  text-align: right;
}

.transaction-row {
  margin-bottom: 16px;
}

.transaction-label {
  color: rgba(86, 90, 113, 1) !important;
  font-size: 14px;
  font-family: "roboto" !important;
}

.transaction-value {
  color: rgba(62, 65, 84, 1) !important;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: "roboto" !important;
}

.transaction-value2 {
  /* color: rgba(62, 65, 84, 1) !important;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: "roboto" !important; */
  color: rgba(62, 65, 84, 1) !important;
  font-weight: 400;
  font-size: 16px;
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Fallback for older browsers */
}

.copy-button {
  color: #007bff;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 8px;
}

/* .status-container {
  padding-left: 24px;
} */

.status-heading {
  color: var(--h-color) !important;
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 16px;
}

.status-subheading {
  color: var(--h-color) !important;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 2px;
}

.status-heading-divider {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: 30px !important;
}

.status-heading-divider2 {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.status-stepper-icon .MuiStepIcon-root {
  color: #0b24c2 !important;
}

.status-icon-bgcolor {
  color: #0b24c2 !important;
  font-size: 25px !important;
}

.status-icon-color {
  width: 24px;
}

.status-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.status-icon {
  color: #0b24c2;
}

.status-info {
  flex: 1;
}

.status-text {
  color: #1a1a1a;
  font-weight: 500;
}

.status-timestamp {
  color: #666;
  font-size: 0.75rem;
  margin-top: 4px;
}

.pl-0 {
  padding-left: 0 !important;
}

.card-sip-details {
  padding: 0px 25px 0px 26px;
}

/* ----------------- */

/* All Mutual Funds Css End */

/* Import External Funds Css Start here  */

.import-funds-card {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ededed;
  max-width: 440px;
  width: 100%;
  border-radius: 16px !important;
}

.external-boder {
  border-bottom: 10px solid #0b24c2;
  width: 55px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.external-otp-input {
  display: flex;
  gap: 1rem;
  /* margin: 1rem 0 2rem 0; */
  align-items: center;
  justify-content: center;
}

.external-otp-restart {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

.external-title.MuiTypography-root {
  font-weight: 600;
  color: #3e4154 !important;
  margin: 0;
  font-size: 48px;
}

.authorize-title.MuiTypography-root {
  font-weight: 600;
  color: #3e4154 !important;
  margin: 0;
  padding-left: 10px;
  font-size: 16px;
}

.external-divider {
  width: 140% !important;
  margin-left: -60px !important;
}

/* Import External Funds Css End here  */

/* alloders css start here  */

.transaction-container {
  margin-top: 20px !important;
  background-color: #ffffff;
  /* padding: 24px; */
}

.filters-section {
  /* padding-right: 24px; */
  border: 1px solid #ededed;
  border-radius: 15px;
  /* padding: 20px; */
}

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-bottom: 24px; */
  padding: 15px;
}

.filters-title {
  color: #3e4154;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.clear-button {
  background: none;
  border: none;
  color: #0b24c2;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  font-family: "roboto";
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 15px;
}

.filter-checkbox {
  color: #3e4154 !important;
}

.transactions-section {
  padding-left: 24px;
}

/* alloders css end here  */

/* ------------ */

.transaction-list {
  /* margin: 16px; */
  margin-left: 25px;
}

.transaction-item {
  padding: 16px 0;
}

.transaction-grid {
  align-items: center;
}

.transaction-date.MuiTypography-root {
  color: #565a71 !important;
}

.transaction-title.MuiTypography-root {
  color: #3e4154;
  font-weight: 500;
  font-size: 18px;
}

.transaction-amount.MuiTypography-root {
  color: #3e4154;
  font-weight: bold;
  font-size: 16px;
}

.transaction-type.MuiTypography-root {
  color: #6b6b6b;
}

.transaction-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-status.MuiTypography-root {
  font-weight: bold;
  font-size: 16px;
}

.transaction-divider {
  border: 0.5px solid #e0e0e0;
  margin: 8px 0;
}

/* ------------ */

.payment-status-container {
  border: 1px solid #ededed !important;
  border-radius: 10px !important;
}

.payment-status-header {
  margin-bottom: 20px;
}

.payment-status-title {
  font-size: 17px;
  font-weight: 600;
  color: #3e4154;
  margin: 0;
}

.payment-status-badge {
  display: flex;
  align-items: center;
  gap: 8px;
}

.payment-status-badge svg {
  color: #e04523;
}

.payment-status-badge span {
  color: #3e4154;
}

.payment-failure-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
}

.payment-failure-content {
  flex: 1;
}

.payment-failure-message {
  color: #3e4154;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 15px;
}

.payment-failure-message svg {
  color: #e04523;
}

.payment-failure-timestamp {
  font-weight: 400;
  color: #565a71;
  font-size: 13px;
}

.payment-refund-notice {
  margin-left: 35px;
  background-color: #ededed;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  color: #3e4154;
  font-size: 14px;
  font-weight: 400;
}

/* Bank Details CSS Start here  */

.bank-mt-17 {
  margin-top: 70px !important;
  margin-bottom: 30px;
}

.bank-text-head.MuiTypography-root {
  font-weight: 600;
  font-size: 20px;
  color: #3e4154;
}

.bank-table-container {
  /* width: 100%;
  max-width: 600px;
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border: 1px solid #ededed;
  border-radius: 10px;
}

.bank-row-bg {
  background-color: #ededed;
}

.bank-row {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
}

.bank-logo-cell {
  width: 50px;
  padding: 16px;
}

/* .bank-logo {
  width: 32px;
  height: 32px;
} */

.bank-name-cell {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.bank-number-cell {
  font-size: 14px;
  color: #888;
  text-align: right;
  padding-right: 16px;
}

.bank-options-cell {
  text-align: right;
  padding-right: 16px;
}

.add-new-bank {
  color: var(--color-blue) !important;
}

.add-bank-container {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #1976d2;
  cursor: pointer;
}

.add-icon {
  margin-right: 8px;
}

.add-bank-text {
  font-size: 14px;
  font-weight: bold;
}

.square {
  width: 8px;
  height: 8px;
  background-color: #1976d2;
  margin-right: 8px;
}

.custom-hr {
  margin: 4px 0;
}

.bank-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.add-bank-header {
  margin-bottom: 24px;
}

.bank-logo {
  width: 48px;
  height: 48px;
}

.bank-logo::before {
  content: attr(alt);
  display: block;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 122px;
  max-width: 200px; /* Added a max-width */
  width: 100%; /* Added to ensure the content takes available space */
  padding: 0 4px; /* Added some padding for better text containment */
  box-sizing: border-box; /* Ensures padding doesn't affect overall width */
}

.sbiIconbank-logo {
  width: 30px;
  height: 30px;
}

.bank-title {
  margin: 0;
  color: #3e4154;
  font-weight: 500;
}

.bank-subtitle {
  margin: 0;
  color: rgba(11, 36, 194, 1);
  font-weight: 600;
  font-size: 14px;
}

.details-container {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 24px;
}

.sub-details-container {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 24px;
}

.details-row {
  padding: 5px 0;
  /* border-bottom: 1px solid #f0f0f0; */
}

.details-label {
  color: rgba(86, 90, 113, 1);
  font-size: 16px;
}

.details-value {
  color: rgba(62, 65, 84, 1);
  font-size: 16px;
  font-weight: 400;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  color: #3e4154;
  /* margin: 24px 0 16px 0; */
}

.autopay-card {
  /* border: 1px solid #f0f0f0; */
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.copy-button {
  color: #0052ff;
  padding: 4px;
  min-width: auto;
}

.edit-button {
  color: #0052ff;
  padding: 4px;
  min-width: auto;
}

.menu-button {
  position: absolute;
  right: 16px;
  top: 16px;
}

.section-title-box {
  margin: 10px 0px 30px 0;
  border-bottom: 1px solid #ededed;
}

.autopay-dialog .dialog-title {
  padding-bottom: 20px;
}

.autopay-dialog .title-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.autopay-dialog .info-icon {
  color: #4285f4;
}

.autopay-dialog .title-text {
  color: #4285f4;
  font-weight: 500;
}

.autopay-dialog .dialog-content {
  padding: 20px 24px;
}

.autopay-dialog .main-question {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
  font-size: 1.25rem;
  font-weight: 400;
}

.autopay-dialog .info-text {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
  font-size: 1rem;
  line-height: 1.5;
}

.autopay-dialog .dialog-actions {
  padding: 16px 24px;
}

.autopay-dialog .cancel-button {
  color: rgba(0, 0, 0, 0.6);
}

.autopay-dialog .confirm-button {
  color: #00c853;
}

.autopay-dialog .confirm-button:hover {
  background-color: #00a844;
}

.bank-verify-title {
  margin: 0;
  color: #3e4154 !important;
  font-weight: 500;
}

.verify-model-radius .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 24px !important;
}

.verify-model-img {
  margin-top: 7px;
}

.edit-bank-details {
  margin-top: -26px !important;
  color: #0b24c2 !important;
}

.calculator-tabs {
  width: 45%;
}

/* Button styles */
.chart-toggle-button {
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 12px !important;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

/* Active Button Style */
.chart-toggle-button.active {
  background-color: rgba(245, 245, 245, 1);
  color: rgba(62, 65, 84, 1);
  border: 2px solid rgba(237, 237, 237, 1) !important;
  border-radius: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Inactive Button Style */
.chart-toggle-button.inactive {
  background-color: #fff;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Hover Effect */
.chart-toggle-button:hover {
  background-color: #f9f9f9;
}

.return-textfield .css-nz481w-MuiInputBase-input-MuiInput-input {
  color: rgba(11, 36, 194, 1);
  padding: 5px 5px 5px;
  font-weight: 700;
  font-size: 17px;
  text-align: end;
}

.return-textfield {
  color: #46a04c;
}

.active-sip-filter .MuiMenu-paper {
  border-radius: 10px !important;
  border: 1px solid #ededed !important;
  box-shadow: none !important;
}

/* Bank Details CSS Start End  */
/* Bank Details CSS Start End  */

/* Adjust width for mobile view */
@media screen and (max-width: 768px) {
  .calculator-tabs {
    width: 100%;
  }

  .chart-toggle-button {
    min-width: 36px !important;
  }

  .sub-details-container {
    border: none;
    padding: 26px 4px;
  }

  .details-value {
    font-size: 14px;
  }

  .transaction-value2 {
    max-width: 100%;
    white-space: normal;
    font-size: 14px;
  }

  .search-header-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 20px;
  }

  .transaction-list {
    margin-left: 0px;
  }
}
