@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h4-small {
  margin: 0;
  color: var(--h-color);
  /* font-family: "Poppins" !important; */
  color: rgba(62, 65, 84, 1) !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-danger {
  color: red;
}
h1,
.h1 {
  font-size: var(--h1-size);
}

h2,
.h2 {
  font-size: var(--h2-size);
}

h3,
.h3 {
  font-size: var(--h3-size);
}

h4,
.h4 {
  font-size: var(--h4-size);
}

h5,
.h5 {
  font-size: var(--h5-size);
  color: var(--black-color);
}

body {
  overflow-x: hidden;
  background-color: var(--tb-body-bg) !important;
  color: rgba(86, 90, 113, 1);
  /* background-color: #ededed; */
}

/* .space-sm {
  padding: var(--space-sm);
} */

:root {
  /* colors  */
  --main-font-color: #3e4154;
  --bg-sky: #f5f7ff;
  --h-color: #3e4154;
  --light-font-color: #565a71;
  --underline-green: #00cb6a;
  --bg-gray: #eeeeee;
  --bg-blue: #0f52ba;
  --color-blue: #0b24c2;
  --white: #fff;
  --text-color: #111111;
  --border-gray: #dedede;
  --ex-light-sky: #f5f9ff;
  /* --tb-body-bg: #f5f5f5; */
  --tb-body-bg: #ffff;

  --h2-size: 26px;
  --h3-size: 24px;
  --h4-size: 20px;
  --h5-size: 18px;
  --h6-size: 16px;
  --p-font-size: 20px;
  --f-size-40: 40px;
  --f-size-13: 13px;
  --f-size-15: 15px;
  --f-size-16: 16px;
  --a-f-size-14: 14px;
  --space: 40px 0;
  --space-pt: 40px;
  --space-pe: 40px;
  --space-md: 80px 0;
  --space-md-pt: 80px;
  --space-sm: 40px 0;
  --space-sm-pt: 40px;
}

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.text-center {
  text-align: center !important;
}

.block {
  display: block !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.bg-none {
  background-color: transparent !important;
}

.w-100 {
  width: 100% !important;
}

.vh-100 {
  height: 100% !important;
}

.m-auto {
  margin: auto !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

img {
  object-fit: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.delete_inner {
  color: red;
}
.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.signature-canvas {
  border: 2px solid #0b24c2;
  border-radius: 15px;
  cursor: crosshair;
}

.signature-buttons {
  display: flex;
  gap: 1rem;
}

.delete_button {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  border: unset;
  background-color: transparent;
  color: #a61e09;
  padding: 10px 0px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 30px;
}
.delete_button span {
  background-color: #a61e09;
  width: 20px;
  height: 20px;
  display: flex;
  color: #ffff;
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
}

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width of the container */
}
.inactive-typo {
  font-size: 20px !important;
  text-align: center;
}

.inactive-typo-under {
  font-size: 12px;
  text-align: center;
  margin-top: 20px !important;
  color: #565a71;
}
.inactive-user {
  color: #e04523;
  padding: 5px 2px;
  text-align: center !important;
  align-self: center;
  display: flex;
  width: 100%;
  justify-content: center !important;
}
.btn-add-bank {
  background-color: #0b24c2 !important;
  margin-left: 15px !important;
  color: white !important;
  display: block !important;
  width: 100% !important;
  /* align-items: center; */
}

.map_ac_inner {
  background: #34d3b7;
}

.set_auto_pay_same_content {
  width: 32px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fatca-page {
  border: 1px solid rgb(224, 220, 220);
  padding: 20px;
  border-radius: 10px;
}
.image-center {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.under-div {
  width: 70px;
  padding: 2px 2px;
  border-radius: 24px;
  background-color: #feecea;
}

.inactive-typo-link {
  color: #0b24c2;
  text-align: center;
  margin-top: 30px !important;
}
.arrow-image {
  width: 60px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
}

.App-link {
  color: #61dafb;
}

h1 {
  font-size: 64px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page_right {
  margin-left: 250px;
}

.page_left {
  margin-left: 80px;
}

.page_center {
  margin-left: 0;
}

.bar_icon {
  color: #111111;
  font-size: 24px;
}
