.view-Ticket{
    margin: 30px;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 20px;
    padding: 35px;
}
.view-text{
    font-size: 33px;
    margin-top: 0;
    margin-bottom: 50px;
    font-weight: 500;
}
@media (max-width:400px){
    .view-text{
        font-size: 25px;
    }
}
.view_button{
    border-radius: 5px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    /* width: 100px; */
    height: 40px;
}
.reply_button{
    border-radius: 5px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    /* width: 100px; */
    height: 40px;
}
.close_button{
    border-radius: 5px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    /* width: 150px; */
    height: 35px;
}
.reply_text{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    flex-wrap: wrap;
}
.page_inner_content_box{
    padding: 30px !important;
}
.Reply_button{
    border-radius: 10px !important;
    padding: 8px 16px;
    width: 130px;
    height: 40px;
    background-color: blue !important;
}
.Reply_button1{
    border-radius: 10px !important;
    padding: 8px 16px;
    width: 130px;
    height: 40px;
    border: 1px solid blue !important;
    color: blue !important;
}
.btn_hover:hover{
    background-color: #0a23c1 !important;
}
.dialog-content-center {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center; /* Center-align text */
    gap: 20px; /* Add spacing between items (optional) */
    min-height: 150px; /* Optional: Adjust based on content */
  }

  .dialog-actions-center.dialog_space{
    padding: 20px !important;
  }
  
  .modal-icon {
    max-width: 100px; /* Optional: Restrict the image size */
    height: auto; /* Maintain aspect ratio */
  }
  
  .dialog-actions-center {
    display: flex !important;
    justify-content: center !important; /* Center buttons horizontally */
    gap: 10px; /* Add spacing between buttons */
  }
  
@media (max-width:1100px){
    .text_responsive{
        font-size: 16px !important;
        white-space: nowrap;
        overflow: visible;
        text-overflow: clip;
    }
    .text_responsive1{
        font-size: 15px !important;
    }
}
@media (max-width:880px){
    .text_responsive1{
        font-size: 13px !important;
    }
}
@media (max-width:1045px){
    .view_button{
        margin-bottom: 10px !important;
        white-space: nowrap;
        overflow: visible;
        text-overflow: clip;
    }
    .reply_button{
        margin-bottom: 10px !important;
        white-space: nowrap;
        overflow: visible;
        text-overflow: clip;
    }
}
@media (max-width:820px){
    .close_button{
        font-size: 12px !important;
        white-space: nowrap;
        overflow: visible;
        text-overflow: clip;    
    }
}
@media (max-width:650px){ 
    .container.view-Ticket{
        margin: 10px !important;
        padding: 15px;
    }
}
@media (max-width: 400px) {
    .reply_text p{
      flex-direction: column; /* Stack items in a column */
      align-items: flex-start; /* Align to the start */
    }
  }