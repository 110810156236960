/* Overall container styling */
/* .reports-container {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
} */

/* Left Section */
.left-section {
    /* background-color: #f9f9f9; */
    padding: 20px;
    /* border-radius: 8px;
    border: 1px solid rgba(237, 237, 237, 1); */
}

.category {
    margin-bottom: 20px;
}

.category-title {
    font-size: 18px;
    font-weight: 600 !important;
    margin-bottom: 10px;
}

.report-item {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.report-item:hover {
    background-color: #e0e0e0;
}

.report-item.active {
    background-color: #e0e0e0;
    /* Active background color */
    color: rgba(62, 65, 84, 1);
    /* Active text color */
}

.report-item.active .arrow-icon {
    color: rgba(62, 65, 84, 1);
    /* Change icon color for active tab */
}

.arrow-icon {
    font-size: 14px !important;
    color: rgba(62, 65, 84, 1);
}

/* Right Section */
.right-section {
    background-color: #ffffff;
    border: 1px solid rgba(237, 237, 237, 1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    /* min-height: 300px; */
    /* margin-right: 10px; */
    /* Ensures a visible container */
}

.border-section {
    border: 1px solid rgba(237, 237, 237, 1);
    padding: 10px;
    border-radius: 8px;
}

.placeholder {
    text-align: center;
    color: #777;
}

.placeholder-icon {
    font-size: 48px;
    color: #1c5db8;
}

.placeholder-text {
    margin-top: 10px;
    font-size: 16px;
}

.date-flex {
    display: flex;
}

.profile-list .css-cveggr-MuiListItemIcon-root {
    min-width: 0px !important;
}



/* Responsive Styles */
@media (max-width: 768px) {
    .left-section {
        padding: 0px;
    }

    .category-title {
        font-size: 16px;
    }

    .placeholder-text {
        font-size: 14px;
    }

    .date-flex {
        display: contents;
    }

    .funcard-padding {
        padding: 0px 30px;
    }
}

@media (max-width: 1324px) {
    .date-flex {
        display: contents;
    }

    .funcard-padding {
        padding: 0px 30px;
    }
}