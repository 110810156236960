.wrapper {
  padding-left: 47px;
  padding-right: 47px;
  min-height: calc(100vh - header-height - footer-height);
}

.text-green {
  color: rgba(70, 160, 76, 1) !important;
}

.text-gray {
  color: rgba(86, 90, 113, 1);
}

.text-red {
  color: rgba(224, 69, 35, 1) !important;
}

.text-blue {
  color: rgba(11, 36, 194, 1);
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.profilepad {
  padding: 15px !important;
}

.loader-p-2 {
  padding: 10px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.7);  */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's above all other content */
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  min-height: 100vh;
}

.table-loader .loader-container {
  width: 40px;
  height: 40px;
}

.table-loader .loader-container svg {
  width: 40px !important;
  height: 40px !important;
}

.p-10 {
  padding: 10px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-84 {
  width: 84%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-8 {
  margin-top: 80px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.mr-2 {
  margin-right: 20px;
}

.label-mar {
  margin-bottom: -15px !important;
}

.kyc-padding {
  padding: 15px !important;
}

.profile-link {
  text-decoration: none;
}

.submit-btn {
  background-color: rgba(11, 36, 194, 1) !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  font-weight: 400 !important;
}

.contact-form-label {
  font-weight: 500;
  font-size: 16px;
}

.back-arrowbtn {
  margin-left: -7px !important;
  color: rgba(62, 65, 84, 1) !important;
}

.red-color {
  color: #f02d3a;
}

.mt-10 {
  margin-top: 100px;
}

.otppopup .css-twia2z-MuiPaper-root-MuiDialog-paper {
  padding: 20px !important;
  border-radius: 24px !important;
}

.check-icon {
  color: rgba(11, 36, 194, 1);
  font-size: 45px !important;
}

.upi-text {
  font-size: 14px !important;
}

.back-bread {
  /* margin-top: 78px; */
  margin-left: 37px;
  margin-right: 37px;
}

.back-bread p {
  font-size: 15px;
  font-weight: 500;
  color: #3e4154;
  margin-top: 2px;
}

.dark-gray {
  color: rgba(62, 65, 84, 1);
}

.redeem-form-check .MuiFormControlLabel-label {
  color: rgba(11, 36, 194, 1);
  /* Label color */
  font-weight: 500;
  /* Optional for bold text */
}

.blue-switchline {
  width: 80px;
  height: 12px;
  background-color: rgba(11, 36, 194, 1);
  margin-top: 20px;
}

.border-top {
  border-top: 1px solid rgba(237, 237, 237, 1) !important;
}

/* Optional for bold text */

/* Change checkbox color */

/* .redeem-form-check .MuiCheckbox-root {
  color: rgba(11, 36, 194, 1);

} */

.redeem-form-check .Mui-checked {
  color: rgba(11, 36, 194, 1) !important;
  /* Checked checkbox color */
}

.copyicon {
  color: rgba(11, 36, 194, 1) !important;
  font-size: 18px !important;
}

.skip-btn {
  cursor: pointer;
  color: var(--h-color);
  font-weight: 600;
  font-size: 15px;
}

.side-margin {
  margin-left: 48px;
  margin-right: 48px;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-watchlist {
  margin-bottom: 100px;
}

.custom-checkbox .MuiSvgIcon-root {
  color: rgba(11, 36, 194, 1);
  /* Default color */
}

/* Checked Checkbox Color */
.custom-checkbox.Mui-checked .MuiSvgIcon-root {
  color: rgba(11, 36, 194, 1);
  /* Color when checked */
}

/* Disabled Checkbox Color */
.custom-checkbox.Mui-disabled .MuiSvgIcon-root {
  color: gray;
  /* Disabled color */
}

.css-7o8aqz-MuiSlider-rail {
  background-color: rgba(60, 60, 67, 0.18) !important;
}

.css-ttgsjq-MuiSlider-track {
  background-color: rgba(11, 36, 194, 1) !important;
}

.css-188mx6n-MuiSlider-root {
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: rgba(0, 0, 0, 0.12) !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: rgba(11, 36, 194, 1) !important;
}

.css-1fkybvm {
  border: none !important;
}

/* Container styling */
.transaction-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.transaction-status-container:last-child {
  border-bottom: none;
}

/* Status info styling */
.status-info {
  display: flex;
  align-items: center;
}

/* Text styling */
.transaction-status {
  font-weight: 400 !important;
  margin-right: 8px;
  color: #565a71;
}

/* Dot styling */
.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px !important;
}

.status-dot.green {
  background-color: green;
}

.status-dot.red {
  background-color: red;
}

/* Arrow icon styling */
.arrow-icon {
  color: #6c757d;
}

.redeem-custom-m {
  margin: -3px 9px;
}

.investcard-textpadding {
  padding: 0px 30px 0px 30px;
}

.contact-form-title {
  font-size: 16px !important;
  color: #4b5561 !important;
}

.holding-cal-icon .css-i4bv87-MuiSvgIcon-root {
  color: #565a71;
}

.hdfc-text {
  font-size: 14px !important;
}

/* Adjust width for mobile view */
@media screen and (max-width: 600px) {
  .back-bread {
    margin-left: 20px;
  }

  .side-margin {
    margin-left: 20px;
    margin-right: 20px;
  }

  .watch-invest-pl {
    padding-left: 0px !important;
  }

  .hdfc-text {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) and (max-width: 1194px) {
  .hdfc-text {
    font-size: 12px !important;
  }
}

.container-mar {
  margin-left: -14px !important;
}

.sakssk.css-1b45dk2 {
  gap: 1px !important;
}

.scanpay-pad {
  border: solid 1px rgba(237, 237, 237, 1);
  padding: 10px;
  border-radius: 10px;
}

.active-button {
  background-color: #0b24c2 !important;
  color: #ffffff !important;
  pointer-events: auto;
  /* Enable clicks when active */
  opacity: 1;
}

.empty-ui {
  height: 100vh !important;
  margin-top: 60px !important;
}

.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 10vh;  */
  width: 100%;
}

.watchlist-icon-mb {
  margin-bottom: auto;
}

.popular-founds-pt {
  padding-top: 10px !important;
}

/* ------------- */

/* #wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#page-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
}

.footer {
  margin-top: auto; 
} */
