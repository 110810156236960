.custom-dialog .MuiDialog-paper {
  width: 1000px;
  height: 600px;
  max-width: 100%;
  border-radius: 8px;
  /* width: 80%; */
  /* height: 80%; */
  /* max-width: none; */
}

.dialog-main-box {
  margin: 0;
  padding: 0;
  width: 1000px;
  height: 600px;
}

.banner-image {
  background-image: url("../images/login-banner.png");
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  /* Light gray for light mode */
  background-size: cover;
  background-position: center;
  height: 600px;
  max-width: 95%;
}

.form-paper {
  margin: 64px 32px;
}

.avatar {
  margin: 8px;
  background-color: #f50057;
  /* Secondary color */
}

.sign-form {
  width: 100%;
  /* Fix IE 11 issue */
  margin-top: 8px;
}

.login-button-submit.MuiButton-root {
  margin: 24px 0 16px;
  background-color: #0b24c2 !important;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: capitalize;
  border-radius: 8px;
}

.login-text.MuiTypography-root {
  color: #3e4154;
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 24px !important;
}

.inputbg .MuiInputBase-input {
  background: #f5f5f5 !important;
}

.login-tems.MuiTypography-root {
  color: #3e4154;
  font-size: 13;
}

.login-tems span {
  font-weight: 600;
}

.dialog-container {
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.dialog-circleicon {
  /* margin-top: 16px; */
}

.change-pass-content {
  padding: 16px;
}

.dialog-title-pass.MuiTypography-root {
  font-weight: bold;
  color: #3e4154;
  font-size: 24px;
}

.change-dialog-actions {
  display: flex !important;
  justify-content: center !important;
}

.dialog-button-change.MuiButton-root {
  background-color: #0b24c2 !important;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: capitalize;
  border-radius: 8px;
}

.dialog-button:hover {
  background-color: #024dbd;
}

.coming-soon-container {
  /* background-color: #f5f5f5; */
}

.coming-soon-image {
  max-width: 56%;
  height: auto;
}

.logo-mishika-menu img {
  margin: 0;
}

.logo-mishika-menu img {
  width: 118px;
  outline: none !important;
  border: none !important;
}

.auth-btn-m {
  margin: 14px 20px !important;
}

.authgoogle {
  width: 70% !important;
  max-width: 400px !important;
  min-width: min-content !important;
  margin: 0;
}

.meun-search-bar {
  margin-right: 10px !important;
}

.login-close-icon-btn {
  float: right;
}

@media (max-width: 768px) {
  .authgoogle {
    width: 100% !important;
  }

  .login-termstext {
    font-size: 11px !important;
  }
}

/* .custom-otp-input {
  width: 40px !important; 
  height: 40px !important; 
  background-color: #f9f9f9 !important; 
  border: 2px solid #007bff !important; 
  border-radius: 6px !important;
  font-size: 18px !important;
  text-align: center;
}

.custom-otp-input:focus {
  border-bottom: 2px solid #0047ff !important;
  outline: "none" !important;
} */

/* OTP Input Style */
.otp-input {
  width: 7vw;
  max-width: 28px;
  height: 8vw;
  max-height: 50px;
  font-size: 1.2rem !important;
  text-align: center !important;
  border: none !important;
  border-bottom: 2px solid #c4c4c4 !important;
  background-color: transparent !important;
  color: #000 !important;
  outline: none !important;
}

.otp-input:focus {
  border-bottom: 2px solid #0047ff !important;
  outline: "none" !important;
}

/* Fixing iPhone width issue */
@media screen and (max-width: 600px) {
  .otp-input {
    width: 2.8vw; /* Adjusted for iPhone */
    height: 10vw;
    max-width: 30px;
    max-height: 30px;
    margin-top: 20px;
  }
}

/* Further fine-tuning for smaller iPhones */
@media screen and (max-width: 375px) {
  .otp-input {
    width: 3vw;
    height: 10vw;
    max-width: 30px;
  }
}
