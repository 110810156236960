.see-more-btn {
    color: #0a23c1 !important;
    text-transform: capitalize !important;
    white-space: nowrap;
}

.ans-column {
    max-width: 250px !important;
    min-width: 250px !important;
}

.question-column {
    max-width: 150px !important;
    min-width: 150px !important;
}