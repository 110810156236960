.pandetails-bg {
    display: flex;
    justify-content: center;
    align-items: center;

}

.bgcolor {
    background-color: #f5f7fb;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.pan-box {
    width: 500px;
}

.custom-checkbox-label {
    font-size: 16px !important;
    /* Default font size */
}

/* For mobile devices with screen width up to 600px */
@media (max-width: 600px) {
    .custom-checkbox-label {
        font-size: 10px !important;
        text-align: justify !important;
        /* Reduced font size for mobile */
    }
}

.pan-border {
    border: 1px solid rgba(237, 237, 237, 1);
    padding: 10px;
    border-radius: 10px;
}

.occupation-btns {
    border: 1px solid rgba(237, 237, 237, 1) !important;
    color: rgba(62, 65, 84, 1);
    padding: 20px;
    border-radius: 8px !important;
    height: 70px !important;
}

.next-btn-margin {
    margin-top: 110px !important;
}

.css-11ys3p3-MuiButtonBase-root-MuiButton-root {
    color: white !important;
}

.custom-radio {
    color: rgba(11, 36, 194, 1) !important;
    /* Unchecked color */
}

/* Checked state */
.custom-radio.Mui-checked {
    color: rgba(11, 36, 194, 1) !important;
    /* Checked color */
}

/* Focus state */
.custom-radio.Mui-focusVisible {
    outline: 2px solid rgba(11, 36, 194, 1) !important;
    /* Optional: Add a focus outline */
}