.who-title {
  font-size: 24px !important;
  /* font-family: "poppins" !important; */
  font-weight: 600 !important;
}

.who-head {
  font-size: 50px !important;
  font-weight: 600 !important;
  /* font-family: "poppins" !important; */
  text-align: center;
}

.whoimg {
  width: auto;
}

/* contact page */
.contact-mail {
  align-items: center;
  display: flex;
  font-size: 18px !important;
}

.contact-mail span {
  font-weight: bold !important;
}

.contact-bg {
  width: 100%;
  max-width: 450px;
  /* margin: 'auto', */
  padding: 2rem;
  border-radius: 24px;
  background-color: rgba(245, 245, 245, 1);
}

@media (max-width: 600px) {
  .who-head {
    text-align: start;
  }
}
