.notification-panel {
  border-radius: 12px !important;
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  min-height: 350px;
  height: 100%;
  position: absolute;
  top: 56px;
  right: 198px;
  overflow: hidden;
  z-index: 1000;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 16px 64px rgba(0, 0, 0, 0.5);
  height: auto !important;
}
.notification-admin {
  border-radius: 12px !important;
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  min-height: 350px !important;
  /* height: 100%; */
  position: absolute;
  top: 66px;
  right: 100px;
  overflow: hidden;
  z-index: 1000;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 16px 64px rgba(0, 0, 0, 0.5);
}
span.badge_counter {
  width: 17px;
  display: flex;
  height: 17px;
  background: #586fff;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.0168em;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  /* right: 9px; */
  color: #ffff;
  left: 20px;
}

/* min-width: 325px;
  max-width: 350px; */

/* --------- */

/* left: 66px; */

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-header-admin {
  padding: 23px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #586fff;
  color: #fff;
}

.notification-header-admin h4 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
}
.notification-header {
  padding: 23px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3583ff;
  color: #fff;
}

.notification-header h4 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
}

.close-btn {
  /* background: transparent; */
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

/* .notification-content {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
  } */

.notification-content {
  padding: 20px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-color: #6e9ce4 #ffffff;
  /* height: 267px !important;
     */
}
.notification-content-admin {
  padding: 20px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-color: #8897fa #ffffff;
  /* height: 267px !important;
     */
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #f9f9f9;
  transition: background 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.notification-item-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #f9f9f9;
  transition: background 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.notification-item.unread {
  /* background: #fffbe6; */
  background: #cfdeffb5;
}

.notification-item-admin.unread {
  /* background: #fffbe6; */
  background: #c1c9ff;
}

.notification-item.read {
  background: #f8f8f8;
  border-color: #ddd;
}
.notification-item-admin.read {
  background: #f8f8f8;
  border-color: #ddd;
}

.notification-item:hover {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.notification-item-admin:hover {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-details {
  flex-grow: 1;
}

.notification-details h6 {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: bold;
  color: #181c32;
}

.notification-details p {
  margin: 0 0 5px;
  font-size: 12px;
  color: #181c32;
}

.notification-details small {
  color: #555555;
  font-weight: bold;
  font-size: 12px;
}

.mark-as-read-btn {
  padding: 5px 10px;
  font-size: 12px;
  background: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s;
}

.mark-as-read-btn:hover {
  background: #0056b3;
}

.no-notifications {
  text-align: center;
  color: #888;
  font-size: 14px;
  padding: 20px;
}

/* @media (max-width: 946px) {
    .notification-panel {
      min-width: 330px;
      max-width: 330px;
      left: 40px;
    }
  } */

@media (max-width: 946px) and (min-width: 322px) {
  .notification-panel {
    height: auto !important;
    min-width: 330px;
    max-width: 330px;
    left: 135px !important;
  }
}

@media (max-width: 522px) {
  .notification-panel {
    min-width: 300px;
    max-width: 300px;
    left: 20px !important;
    /* margin-left: -15px !important; */
    height: auto !important;
  }
}

@media (max-width: 946px) and (min-width: 391px) {
  .notification-admin {
    min-width: 330px;
    max-width: 330px;
    /* left: 40px; */
  }
}

@media (max-width: 400px) {
  .notification-admin {
    min-width: 330px;
    max-width: 330px;
    /* max-height: 230px;
      min-height: 230px; */
    left: 28px;
  }
}
