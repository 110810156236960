.setupcard {
  border: solid 1px rgba(237, 237, 237, 1);
  border-radius: 12px;
  padding: 20px;
  /* Set the desired card height */
}

.popular-fundcard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 165px;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.recent-view-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.card-content-link {
  text-decoration: none;
  color: inherit;
}

.image-section img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  /* object-fit: contain; */
}

.fundcardhead {
  font-size: 16px !important;
  /* Adjust as needed */
  font-weight: 600 !important;
  /* Adjust as needed */
  line-height: 1.2 !important;
  /* Set line height to control spacing */
  height: 2.4em;
  /* 1.2em line-height * 2 lines */
  overflow: hidden;
  /* Hide overflowing text */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  /* Vertical orientation */
  text-overflow: ellipsis;
  /* Show ellipsis (...) */
}

.bottom-align {
  margin-top: auto;
  /* Pushes content to the bottom */
}

.alllink {
  color: rgba(11, 36, 194, 1);
  font-weight: 500 !important;
}

.square {
  height: 20px;
  width: 20px;
  background-color: rgba(235, 235, 235, 1);
  margin-right: 10px;
}

.watchlist-card {
  border: solid 1px rgba(237, 237, 237, 1);
  border-radius: 12px;
  /* background: #fff; */
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.watchlist-card-box {
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.watchlist-card-container {
  position: fixed;
  right: 20px;
  width: 400px;
  max-width: 90vw;
  max-width: 100%;
  border-radius: 12px;
  padding: 16px;
}

/* 1000px se chhoti screen ke liye full screen */
@media (max-width: 1100px) {
  .watchlist-card-container {
    position: unset !important;
    right: unset !important;
    /* width: unset !important; */
    /* max-width: unset !important; */
    /* max-width: unset !important; */
    overflow: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    z-index: unset !important;
    width: 100% !important;
  }
}

.watchlist-textpadding {
  padding: 0px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
}
.watchlist-textpadding-custom {
  padding: 0px 20px 0px 20px !important;
}

.watchlist-textpadding2 {
  padding: 0px 30px 0px 30px;
}

.watchlist-head {
  font-size: 14px !important;
}

.h-50 {
  height: 50% !important;
}

.watchlist-subhead {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-hr {
  border: none;
  border-top: 1px solid rgba(237, 237, 237, 1);
  margin: 0;
}

.collextion-text {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.collection-card {
  padding: 15px;
  border: solid 1px rgba(237, 237, 237, 1);
  border-radius: 12px;
  height: 80%;
}

.explore-sections-head {
  font-weight: 600 !important;
  font-size: 20px !important;
}

.quick-text {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.card-padding {
  padding: 0px 30px 0px 30px;
}

.investments-title-mt {
  margin-top: -4px;
}

.no-popular-funds {
  width: 100%;
  min-height: 20vh;
}
