/* Custom File Input */
.custom-file-input {
  display: none; /* Hide the default file input */
}

.custom-file-input-label {
  display: flex;
  width: 50%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.custom-file-input-label:hover {
  background-color: #e9e9e9;
}

.custom-file-input:disabled + .custom-file-input-label {
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.file-upload-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-name {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
  font-style: italic;
}
/* .page_inner_content_box{
    background-color: #E8ECF1;
  } */
.form_button {
  margin-left: 25px;
}
.form_button button {
  border-color: #0a23c1 !important;
  color: #0a23c1 !important;
}
.form_button button:hover{
  background-color: transparent !important;
}
.next_btn {
  margin-right: 25px !important;
  background-color: #0a23c1 !important;
}
.mailback_space{
  margin-top: 30px;
  padding: 20px 20px !important;
}
@media (max-width: 1020px) {
  .mailback_form {
    display: inline !important;
  }
}
@media (max-width: 920px) {
  .mailback_form {
    display: grid !important;
  }
  /* .file-name{
        display: grid !important;
    } */
  .custom-file-input-label {
    width: 80%;
  }
}
@media (max-width: 847px) {
  .mailback_form {
    display: inline !important;
  }
  .custom-file-input-label {
    width: 100%;
  }
}
@media (max-width: 730px) {
  .mailback_form {
    display: inline !important;
  }
  .custom-file-input-label {
    width: 100%;
  }
  /* .file-name{
        display: grid !important;
    }  */
    .mailback_space{
      padding: 20px 0 !important;
    }
}
@media (max-width: 715px) {
  .mailback_form {
    display: inline !important;
  }
  .custom-file-input-label {
    width: 90%;
  }
  .file-name {
    display: grid !important;
  }
}
@media (max-width: 400px){
  .file-upload-container{
    display: block;
  }
}