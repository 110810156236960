.custom-footer-wrapper {
  /* position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--bg-sky) !important; */

  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--bg-sky) !important;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  height: 50px; */

  width: 100%;
  background: var(--bg-sky) !important;
  text-align: center;
  padding: 10px 0;
}

.main-container {
  /* min-height: 100vh;
  display: flex;
  flex-direction: column; */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container-content {
  /* flex: 1;
  padding-bottom: 50px; */
  flex-grow: 1;
}

/* .footer-min-height {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
} */

/* /////////////////////// */

#footer {
  background: var(--bg-sky);
}

#footer .footer-card {
  width: 25%;
  border: none;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
}

.space-sm {
  /* padding: 40px 0; */
  padding-top: 34px;
  padding-bottom: 24px;
}

#footer .footer-card:nth-child(1) {
  max-width: 400px;
}

/* #footer .footer-card:nth-child(2),
#footer .footer-card:nth-child(3),
#footer .footer-card:nth-child(4) #footer .footer-card:nth-child(5) {
  padding-left: 40px;
} */

#footer .footer-card:nth-child(1),
#footer .footer-card:nth-child(2),
#footer .footer-card:nth-child(3),
#footer .footer-card:nth-child(4) {
  padding-left: 40px;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.first-Col {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo {
  width: 150px;
}

.footer-logo img {
  width: 80%;
  height: 100%;
}

#footer .footer-logo-section-text {
  font-size: 18px;
  font-weight: 600;
  color: var(--main-font-color);
  line-height: 28px;
}

.img-container p {
  margin-left: 15px;
  margin-top: 20px;
  text-align: left;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.social-icons {
  /* margin-top: 26px; */
  margin-top: 15px;
  display: flex;
  justify-content: left;
}

.social-icons1 {
  /* margin-top: 26px; */
  margin-top: 8px !important;
  display: flex;
  justify-content: left;
}

.social-icon-single {
  width: 36px;
  height: 36px;
  margin-left: 0;
  margin-right: 0px;
  position: relative;
}

.social-icon-single::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-blue);
  /* background-color: #ffffff; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 0;
}

.icons-footer {
  margin-right: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.social-icon-single:nth-child(1),
.social-icon-single:nth-child(2) {
  margin-right: 20px;
}

.social-icons-footer {
  /* width: 26px;
  height: 26px;
  margin-right: 10px;
  object-fit: cover; */
  color: #3e4154;
  margin-right: 10px;
  object-fit: cover;
  padding-top: 5px;
}

.app-icon-single {
  margin: 0;
}

.app-icon-single:nth-child(1),
.app-icon-single:nth-child(2) {
  margin-right: 20px;
}

.app-icons {
  margin-top: 42px;
  display: flex;
  justify-content: left;
}

.app-icons-child {
  margin-right: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  object-fit: cover;
}

.copy-rights {
  margin-top: 10px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #001C47; */
  flex-direction: row !important;
  justify-content: left !important;
}

.copy-rights-text {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none !important;
  color: #001c47 !important;
  outline: none;
}

.img-container h5 {
  text-align: left;
  margin-top: 5px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #afb5c0;
}

.list-container {
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  /* margin-top: 140px; */
  /* margin-left: 50px; */
}

.list-container a {
  text-decoration: none;
  color: var(--main-font-color);
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 16px;
}

.list-container a:hover {
  color: #0b24c2;
}

.list-container .no-hover:hover {
  color: var(--main-font-color) !important;
  /* Removes hover effect for "Company" */
}

.list-container .footer-link-light {
  font-size: 16px;
  font-weight: 400;
}

.list-container h6 {
  text-align: left;
}

.list-container h6 a {
  font-weight: 400;
}

.list-container2 {
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* margin-top: 162px; */
}

.list-container2 .footer-contact-content {
  margin-top: 10px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #001c47;
  flex-direction: row !important;
  justify-content: left !important;
}

.contact-heading {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.sideby-links {
  /* color: var(--h-color); */
  text-decoration: none;
  transition: color 0.3s ease;
}

.sideby-links a:hover {
  color: #0b24c2 !important;
}

/* ------------------ */

.mui-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 1);
  /* Default background color */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.mui-icon-container .icon-size {
  font-size: 20px;
  color: #555;
  /* Default icon color */
  transition: color 0.3s ease-in-out;
}

/* Hover effects */
.mui-icon-container:hover {
  transform: scale(0.9);
  /* Makes the icon pop slightly */
  background-color: #ddd;
  /* Optional: Lighten the background */
}

.mui-icon-container.facebook:hover {
  background-color: rgba(0, 142, 247, 1);
  /* Facebook blue */
}

.mui-icon-container.facebook:hover .icon-size path {
  fill: #fff;
  /* Change icon color to white */
}

.mui-icon-container.linkedin:hover {
  background-color: rgba(0, 142, 247, 1);
  /* LinkedIn blue */
}

.mui-icon-container.linkedin:hover .icon-size path {
  fill: #fff;
  /* Change icon color to white */
}

.mui-icon-container.instagram:hover {
  background-color: rgba(0, 142, 247, 1);
  /* Instagram blue */
}

.mui-icon-container.instagram:hover .icon-size path {
  fill: #fff;
  /* Change icon color to white */
}

.mui-icon-container .MuiStack-root {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  background-color: #f5f7fa !important;
  /* Light background color */
  transition: transform 0.3s, background-color 0.3s !important;
}

.icon-size {
  color: #6e7076 !important;
  font-size: 30px !important;
}

/* .mui-icon-container:hover {
  transform: scale(1.1);
} */

.facebook {
  color: #6e7076;
  /* Default color */
}

.facebook:hover {
  background-color: #3b5998;
  /* Facebook hover color */
  color: white;
}

.linkedin {
  color: #6e7076;
  /* Default color */
}

.linkedin:hover {
  background-color: #0e76a8;
  /* LinkedIn hover color */
  color: white;
}

.instagram {
  color: #6e7076;
  /* Default color */
}

.instagram:hover {
  background-color: #e4405f;
  /* Instagram hover color */
  color: white;
}

/* ------------------ */

.quick-link-custom {
  margin-top: 0;
}

/* .footer-email-link {
  position: absolute;
  margin-left: 32px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
} */

.footer-email-link {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

/* old media query  */

@media (max-width: 1199px) {
  .first-Col {
    flex-wrap: wrap;
    gap: 10px;
  }

  #home2-footer .footer-logo {
    max-width: 120px;
  }

  #home2-footer .footer-logo-section-text {
    font-size: 14px;
  }

  #home2-footer .list-container a,
  #home2-footer .list-container2 a {
    font-size: 14px;
  }

  #footer .footer-card:nth-child(1),
  #footer .footer-card:nth-child(2),
  #footer .footer-card:nth-child(3),
  #footer .footer-card:nth-child(4) {
    padding-left: 0px !important;
  }
}

@media (max-width: 991px) {
  .img-container p br {
    display: none;
  }

  .img-container {
    padding-right: 10px;
    width: 100%;
  }

  .grid-container {
    padding: 60px 0;
  }

  .img-container .img1 {
    width: 120px;
  }

  .first-Col {
    max-width: 100%;
  }

  #footer .footer-logo-section-text {
    font-size: 16px;
  }

  .list-container a {
    font-size: 14px;
  }

  .list-container2 a {
    font-size: 14px;
  }

  #footer .footer-card:nth-child(1),
  #footer .footer-card:nth-child(2),
  #footer .footer-card:nth-child(3),
  #footer .footer-card:nth-child(4) {
    padding-left: 0px !important;
  }

  .footer-logo {
    width: 120px;
  }

  #home2-footer .footer-card {
    width: 32%;
  }

  #home2-footer .footer-logo-section-text {
    margin-left: 0;
  }

  #home2-footer .footer-card:nth-child(4) {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .first-Col {
    width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .grid-container {
    flex-direction: column;
  }

  .second-col {
    width: 100%;
    margin-top: 30px;
  }

  #footer .footer-card {
    width: 32%;
  }

  #home2-footer .footer-card {
    width: 48%;
    margin-bottom: 30px;
  }

  #home2-footer .footer-card:nth-child(4) {
    width: 48%;
  }

  #home2-footer .list-container2 a {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  #footer .Col {
    width: 100%;
  }

  .img-container {
    width: 100%;
  }

  .list-container2 h5,
  .list-container2 h6 {
    margin-left: 0;
  }

  .img-container p {
    margin-left: 0;
  }

  .social-icons {
    margin-top: 15px;
  }

  .social-icon-single {
    width: 40px;
    margin-right: 30px;
  }

  .icons-footer {
    width: 100%;
    margin-right: 0;
  }

  .img-container h5 {
    margin-left: 0;
  }

  #footer .Col:nth-child(2) {
    margin-top: 20px;
  }

  #footer .footer-card:nth-child(1) {
    max-width: 100%;
  }

  #footer .footer-card {
    width: 100%;
    margin-bottom: 30px;
  }

  .first-Col {
    column-gap: 20px;
  }

  #home2-footer .footer-card:nth-child(4) {
    width: 100%;
  }
}

/* ----------------- */

/* New Section Styles */

/* 
.footer-bottom-section {
  padding: 20px 0;
}

.footer-bottom-section .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-bottom-left,
.footer-bottom-right {
  width: 48%;
  font-size: 12px;
}

.footer-bottom-right {
  text-align: right;
}

.popular-funds,
.fund-companies {
  margin-bottom: 20px;
}

.popular-funds-title,
.fund-companies-title {
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
}

.popular-funds a,
.fund-companies a {
  color: #007bff;
  text-decoration: none;
  font-size: 12px;
  margin-right: 5px;
}

.popular-funds a:hover,
.fund-companies a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-bottom-left,
  .footer-bottom-right {
    width: 100%;
    text-align: center;
  }

  .footer-bottom-section .container {
    flex-direction: column;
    align-items: center;
  }
} */

.footer-bottom {
  /* background-color: #f8f8f8; */
  color: #333;
  padding: 20px 0;
  font-family: Arial, sans-serif;
}

.botton-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 0 20px; */
}

.copyright {
  text-align: center;
  margin-bottom: 50px;
  font-size: 16px;
  color: var(--h-color);
}

.foot-title {
  font-size: 24px;
  font-weight: 400;
  /* margin-bottom: 10px; */
  color: rgba(86, 90, 113, 1);
}

.subsection-title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "poppins";
  color: rgba(62, 65, 84, 1);
}

.link-list {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.link-list a {
  color: rgba(62, 65, 84, 1);
  font-size: 16px;
  font-family: "poppins";
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-list a:hover {
  color: #0b24c2;
}

.link-list a:not(:last-child) {
  margin-right: 5px;
  /* margin-left: 5px; */
}

@media (max-width: 768px) {
  .botton-container {
    padding: 0;
  }

  .section-title {
    font-size: 16px;
  }

  .subsection-title {
    font-size: 14px;
  }

  .link-list {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer-bottom {
    padding: 15px 0;
  }

  .copyright {
    font-size: 14px;
  }

  .section-title {
    font-size: 14px;
  }

  .subsection-title {
    font-size: 13px;
  }

  .link-list {
    font-size: 11px;
  }
}

@media (max-width: 1199px) and (min-width: 577px) {
  .quick-link-custom {
    margin-top: -100px;
    /* Margin-top for screens between 1199px and 577px */
  }
}
