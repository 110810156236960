/* Container Styling */
.transactions-container {
  font-family: Arial, sans-serif;
  /* margin: 20px auto; */
  /* max-width: 90%; */
  /* padding: 20px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.table_head {
  color: #545454 !important;
  font-size: 14px !important;
}

.transactions-container h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Table Styling */
.transaction-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.transaction-row {
  /* display: table-row; */
  /* border-bottom: 1px solid #ddd; */
}

/* .transaction-row.header {
  font-weight: bold;
  color: white;
  margin-bottom: 0px !important;
} */

/* .transaction-cell {
    display: table-cell;
    padding: 12px 15px;
    text-align: left;
    vertical-align: middle;
  } */

/* Alternate Row Colors */
.transaction-row.even-row {
  /* background-color: #f4f6f9; */
}

/* Status Colors */
.transaction-cell.success {
  color: green;
  font-weight: bold;
}

.transaction-cell.rejected {
  color: red;
  font-weight: bold;
}

.transaction-cell.pending {
  color: orange;
  font-weight: bold;
}

.transactions-container {
  padding: 16px;
  /* overflow-x: auto;  */
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

/* .transaction-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
} */

.transaction-row.header {
  /* background-color: #f5f5f5; */
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0 !important;
  border: none;
}

.transaction-cell {
  flex: 1;
  text-align: left;
  padding: 8px;
  color: rgb(64, 64, 64);
}

.transaction-cell.status {
  text-transform: capitalize;
}

/* Status Colors */
.transaction-cell.success {
  color: green;
  background-color: #d4edda;
  border-radius: 4px;
  text-align: center;
}

.transaction-cell.rejected {
  color: red;
  background-color: #f8d7da;
  border-radius: 4px;
  text-align: center;
}

.transaction-cell.pending {
  color: orange;
  background-color: #fff3cd;
  border-radius: 4px;
  text-align: center;
}

.transaction-cell.authorized {
  color: purple;
  background-color: #e2d9f3;
  border-radius: 4px;
  text-align: center;
}

.transition_search{
  width: auto !important;
}
.transition_select{
  margin-left: 0 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transaction-row {
    flex-wrap: wrap;
  }

  .transaction-cell {
    flex: 100%;
    /* Stack cells in small screens */
    padding: 8px 0;
  }
}

.status-badge {
  display: inline-block;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  text-align: center;
  min-width: 80px;
}

/* Colors for each status */
.status-badge.success {
  color: #28a745;
  background-color: #d4edda;
}

.status-badge.rejected {
  color: #dc3545;
  background-color: #f8d7da;
}

.status-badge.pending {
  color: #ffc107;
  background-color: #fff3cd;
}

.status-badge.authorized {
  color: #6f42c1;
  background-color: #e2d9f3;
}

/* Header with filter icon */
.status-header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  cursor: pointer;
}

.filter-icon {
  margin-left: 8px;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
}

/* Dropdown Menu */
.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0px;
  z-index: 10;
  min-width: 120px;
}

.dropdown-content div {
  padding: 0 10px;
}

.dropdown-content div:hover {
  background-color: rgb(238, 238, 238);
}

.dropdown-item {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Responsiveness */
@media (max-width: 768px) {
  .transaction-row {
    flex-wrap: wrap;
  }

  .transaction-cell {
    flex: 100%;
    padding: 8px 0;
  }
}

/* Search Input Container */
.position-relative {
  position: relative;
}

/* Search Icon Styling */
.search-icon-custom {
  position: absolute;
  top: 50%;
  left: 12px;
  /* Adjust the distance from the left edge */
  transform: translateY(-50%);
  font-size: 18px;
  /* Adjust icon size */
  color: #999;
  /* Icon color */
  pointer-events: none;
  /* Makes the icon unclickable */
  margin-top: 10px;
}

/* Search Input Styling */
.search-input-custom {
  width: 100%;
  /* Full width of the parent container */
  padding: 13px 40px;
  /* Add padding for the icon */
  font-size: 16px;
  /* Adjust font size */
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 4px;
  /* Rounded corners */
  outline: none;
  /* Remove outline on focus */
  color: #333;
  /* Text color */
  background-color: #fff;
  /* Background color */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  transition: all 0.2s ease-in-out;
  /* Smooth transition effect */
  margin-top: 24px;
}

/* Hover and Focus Effects */
.search-input-custom:hover,
.search-input-custom:focus {
  border-color: #007bff;
  /* Highlight border color on hover/focus */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); Slightly larger shadow on focus */
}

/* Dropdown Label Styling */
.dropdown-label-custom {
  font-size: 14px;
  /* Smaller font size for the label */
  font-weight: 500;
  /* Medium font weight for readability */
  color: #333;
  /* Neutral text color */
  margin-bottom: 8px;
  /* Spacing between label and dropdown */
  display: block;
  /* Ensure label is on a separate line */
}

/* Dropdown Styling */
.form-select {
  width: 100%;
  /* Full width of the parent container */
  padding: 10px;
  /* Add padding for better spacing */
  margin-left: 100px;
  font-size: 14px;
  /* Font size for the dropdown options */
  border: 1px solid #ccc;
  /* Default border color */
  border-radius: 8px;
  /* Rounded corners */
  color: #666;
  /* Text color */
  background-color: #fff;
  /* Dropdown background color */
  appearance: none;
  /* Remove native browser styles */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23999' d='M0 0h4L2 3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px 12px;
  outline: none;
  /* Remove outline on focus */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for hover and focus */
}

.form-select option {
  border-radius: 10px;
}

.form-select1 {
  width: 100%;
  /* Full width of the parent container */
  padding: 10px;
  /* Add padding for better spacing */
  margin-left: 130px;
  font-size: 14px;
  /* Font size for the dropdown options */
  border: 1px solid #ccc;
  /* Default border color */
  border-radius: 8px;
  /* Rounded corners */
  color: #666;
  /* Text color */
  background-color: #fff;
  /* Dropdown background color */
  appearance: none;
  /* Remove native browser styles */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23999' d='M0 0h4L2 3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px 12px;
  outline: none;
  /* Remove outline on focus */
  transition: all 0.3s ease-in-out;
  /* Smooth transition for hover and focus */
}

/* Hover and Focus Effects */
.form-select:hover,
.form-select:focus,
.form-select1:hover,
.form-select1:focus {
  border-color: #007bff;
  /* Highlight border color */
  /* box-shadow: 0 0 4px rgba(0, 123, 255, 0.4); Subtle shadow effect */
}

/* Adjust spacing for the entire dropdown container */
.dropdownBox {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.search-container {
  width: 90%;
  display: flex;
}

.text {
  margin-left: 100px;
}

.text1 {
  margin-left: 130px;
}

/* Export Button */
.export-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #0a23c1;
  /* Blue background */
  color: white;
  /* White text */
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.export-button:hover {
  background-color: #0033cc;
  /* Darker blue on hover */
  text-decoration: none;
}

.export-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 70, 255, 0.3);
  /* Focus ring */
}

/* Export Icon Styling */
.export-icon {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  vertical-align: middle;
}

.failed-text {
  text-align: center !important;
}

/* Apply styles for screens smaller than 1045px */
@media (max-width: 1045px) {
  .MuiTable-root {
    width: 100%;
    /* table-layout: fixed;  */
  }

  .MuiTableCell-root {
    font-size: 0.875rem;
    /* Adjust font size for smaller screens */
    padding: 8px;
    /* Reduce padding */
  }

  /* Make sure the container is scrollable horizontally when needed */
  .MuiTableContainer-root {
    overflow-x: auto;
  }

  .dropdown-content {
    position: absolute;
    background-color: white;
    z-index: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media (max-width: 1045px) {
  .dropdownBox {
    display: block;
  }

  .text {
    margin-left: 10px;
    margin-top: 20px;
  }

  .text1 {
    margin-left: 10px;
    margin-top: 20px;
  }

  .form-select {
    margin-left: 10px;
  }

  .form-select1 {
    margin-left: 10px;
  }
}

.responsive-text {
  /* text-wrap: nowrap; */
}

@media (max-width: 730px) {
  .responsive-text {
    /* font-size: 13px !important; */
  }
}

@media (max-width: 624px) {
  .responsive-text {
    /* font-size: 10px !important; */
  }
}

@media (max-width: 840px) {
  .responsive-text {
    /* text-wrap: nowrap; */
  }
  .responsive-text1 {
    /* font-size: 12px !important; */
    text-wrap: nowrap;
  }
}

@media (max-width: 680px) {
  .responsive-text1 {
    /* font-size: 10px !important; */
  }

  .transactions-container {
    padding: 0px;
  }

  .page_inner_content_box {
    padding: 10px !important;
  }

  .page_background {
    padding: 10px;
  }
}

@media (max-width: 580px) {
  .responsive-text1 {
    /* font-size: 9px !important; */
  }

  .transactions-container {
    padding: 0px;
  }

  .page_inner_content_box {
    padding: 5px !important;
  }

  .page_background {
    padding: 10px;
  }
}