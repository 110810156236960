.header-container.MuiAppBar-root {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 1rem 1.5rem !important;
  height: 30px;
}

.web-header-bm.MuiAppBar-root {
  height: unset !important;
  /* border-bottom: 1px solid #e6f0ed !important; */
}

.suggestions-dropdown {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.toolbar.MuiToolbar-root {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.logo-mishika {
  outline: none !important;
  border: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.logo-mishika img {
  width: 160px;
  outline: none !important;
  border: none !important;
}

.logo-mishika-mobile img {
  width: 100px;
}

.nav-links {
  display: flex;
  /* gap: 1.5rem; */
  gap: 20px;
}

.nav-link {
  color: #3e4154 !important;
  text-decoration: none !important;
  transition: color 0.3s ease !important;
  text-transform: capitalize !important;
}

.nav-link:hover {
  color: #0b24c2 !important;
}

.nav-link.selected {
  color: #0b24c2 !important;
}

.login-button.MuiButton-root {
  background-color: #0b24c2 !important;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: capitalize;
  border-radius: 8px;
}

.login-button:hover {
  background-color: #1d4ed8;
}

.login-btn-mobile {
  font-size: 11px !important;
}

/* CSS for Appbar Mobile */

.header-menu-link {
  color: white;
  text-decoration: none;
}

.header-menu-link:last-child {
  margin-right: 0;
  /* Remove margin for the last item */
}

.header-menu {
  margin-top: 10px;
}

.header-menu-item {
  padding: 8px 16px;
}

.menu-dropsub-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.menu-drop-text {
  font-size: 0.875rem;
  color: #555;
}

.menu-bg-color.MuiMenu-root ul {
  background-color: #1b3baa !important;
  color: #ffffff !important;
}

.menu-icons-color {
  color: #0b24c2;
}

.link-content .MuiAccordionSummary-content {
  margin: 0px !important;
}

/* header my account tooltip css*/

.profile-menu-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.profile-avatar {
  margin-right: 10px;
  cursor: pointer;
}

.profile-name {
  margin-right: 8px;
  font-weight: bold;
}

.profile-id {
  margin-right: 8px;
  color: grey;
}

.dropdown-icon-button {
  padding: 0;
  cursor: pointer;
}

.menu-avatar {
  margin-right: 8px;
}

.menu-icon {
  margin-right: 8px;
}

.header-button.active {
  color: #ffffff;
  /* Active tab text color */
  background-color: #1c5db8;
  /* Active tab background color */
  padding: 19px;
}

.profile-card-padding {
  padding: 0 20px;
}

.transparent-button {
  background-color: transparent;
  border: transparent;
  color: rgba(62, 65, 84, 1);
  font-weight: 600;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Roboto';
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.transparent-button:hover {
  background-color: rgba(233, 233, 235, 1);
  /* Background on hover */
  /* color: white; */
  /* Text color on hover */
}

.transparent-button:active {
  transform: scale(0.95);
  /* Click effect */
}

.transparent-button:focus {
  outline: none;
  /* Remove focus outline */
}

.profile-list span {
  color: rgba(62, 65, 84, 1);
  font-size: 14px;
  font-weight: 500;
}

/* header my account tooltip cs */

/* Default (Desktop) */
.desktop-content {
  display: block;
  /* Show desktop content */
}

.mobile-content {
  display: none;
  /* Hide mobile content */
}

/* Mobile (max-width: 990px) */
@media (max-width: 990px) {
  .desktop-content {
    display: none;
    /* Hide desktop content */
  }

  .mobile-content {
    display: block;
    /* Show mobile content */
  }
}

/* *******************************************mobile header ************************************* */
.header-select-link {
  /* color: #004a91 !important; */
  color: var(--h-color) !important;
  text-transform: capitalize !important;
  text-decoration: none !important;
  font-weight: bold !important;
}

.css-zza0ns-MuiBadge-badge {
  background-color: rgba(11, 36, 194, 1) !important;
}

.myprofile-menu .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 10px !important;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.header-container1 {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.toolbar1 {
  padding: 0 24px;
}

.logo-mishika1 img {
  height: 40px;
}

.transparent-button1 {
  border: none;
  background-color: transparent;
  color: #ff0000;
  font-size: 14px;
  cursor: pointer;
}

.logo-mishika1 {
  display: none;
}

@media (min-width: 600px) {
  .css-u4fbkj-MuiToolbar-root {
    min-height: 85px !important;
  }
}