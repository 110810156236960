.report_box {
  border: 1px solid #ededed;
  border-radius: 15px;
}

.report_box .main-heading-title{
    padding: 30px;
}

.report_box .sub-title{
    padding: 20px 30px;
    border-top: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.summary_report tbody tr{
  background-color: #f5f6ff;
  margin-bottom: 20px;
}

.summary_report tbody tr td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.summary_report tbody tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.footer_data{
  background-color: #e4e6f2 !important;
}