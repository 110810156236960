/* App.css */
/* home.css */
.heroSectionContainer {
  min-height: 50vh;
  background: linear-gradient(180deg, #ffffff 34.82%, #e6f0ed 100%);
  border-radius: 0px 0px 100px 100px;
  text-align: start;
  padding-left: 70px;
  padding-right: 70px;
  margin-top: 0px !important;
}

.heroText {
  font-size: 64px !important;
  /* font-family: "Poppins" !important; */
  font-weight: bold !important;
  color: #2d2d2d;
  margin-bottom: 16px;
  line-height: 96px !important;
}

.heroText span {
  color: rgba(0, 142, 247, 1);
}

.heroSubText {
  font-size: 17px !important;
  color: #6c757d;
  margin-bottom: 24px;
}

.getStartedBtn {
  background-color: rgba(11, 36, 194, 1) !important;
  color: #fff;
  width: 136px;
  height: 48px;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.getStartedBtn:hover {
  background-color: #0039cb;
}

.avatarContainer {
  position: relative;
  display: inline-flex;
}

.avatar {
  background-color: #2962ff;
  color: #fff;
  margin-left: 8px;
}

/* .heroSectionContainer {
    min-height: 80vh;
} */

/* *************************second section**********************/

.secondsectionbg {
  background-color: rgba(245, 247, 255, 1);
  margin-top: 50px;
  padding: 40px;
  text-align: start;
  border-radius: 40px;
}

/* ******************************handpicked section ********************************** */

.handpicked-head {
  font-size: 36px !important;
  font-weight: 600 !important;
}

.grid-item {
  /* transition: color 0.3s ease, background-color 0.3s ease;
    padding: 10px; */
  padding: 20px;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  /* Smooth transition for hover effects */
}

.grid-item img {
  transition: filter 0.3s ease;
  height: 60px;
  /* Smooth transition for image hover effect */
}

.grid-item:hover {
  /* color: white; */
  /* Change text color to white */
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  /* Add a border effect */
  box-shadow: 0px 4px 8px 0px rgba(181, 181, 181, 0.32);

  /* Optional: Add background color change on hover */
}

.grid-link {
  text-decoration: none !important;
  /* Remove underline from link */
  color: rgba(62, 65, 84, 1) !important;
  /* Inherit text color */
  display: block;
  /* Make the link fill the entire grid item */
}

/* ******************start SIP ******************** */

.startsip {
  background-image: url("../images/startsipbg.svg");
  background-size: cover;
  /* Ensures the image covers the entire width and height */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  text-align: center;
  /* Prevents the image from repeating */
  width: 100%;
  height: 100%;
  height: 70vh;
}

.startsip-head {
  font-weight: bold !important;
  color: white;
  font-size: 64px !important;
}

.steps-div {
  background: rgba(245, 245, 245, 1);
  border-radius: 24px;
  padding: 30px;
}

.steps-head {
  font-size: 48px !important;
  font-weight: 600 !important;
  color: rgba(62, 65, 84, 1);
}

/* *************************FAQ Section********************* */

.faqbg {
  background-color: rgba(245, 245, 245, 1);
  padding-left: 60px;
  padding-right: 60px;
}

.faqhead {
  font-size: 20px;
  font-weight: 600;
  font-family: "Open Sans";
}

.faqhead span {
  color: rgba(2, 82, 163, 1);
  margin-right: 10px;
}

.faqheadbg {
  background-color: transparent !important;

  box-shadow: none !important;
  /* Subtle shadow for better visibility */
}

.faq-ans {
  /* font-family: "roboto"; */
  color: rgba(86, 90, 113, 1);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  background-color: transparent !important;
}

/* **************************Testimonial section************************ */
.testi-bg {
  background-color: rgba(245, 247, 255, 1);
}

.testi-head {
  font-size: 48px !important;
  font-weight: 500 !important;
}

.texticard {
  max-width: 300px;
}

/* *****************investment section ************* */
.import-fundbtn {
  background-color: rgba(11, 36, 194, 1) !important;
  color: #fff;
  height: 48px;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.investhead {
  font-size: 36px !important;
  font-weight: 600 !important;
}

.investbg {
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 90px 20px;
}

/* **************************Testimonial section************************ */

.cta-container {
  background-color: #0c172d;
  padding: 48px;
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-card-bg {
  background-color: #3e4154 !important;
}

.contact-card-bg {
  background-color: #ffffff !important;
}

.cta-content {
  max-width: 60%;
  margin-left: 50px;
}

.cta-subtitle.MuiTypography-root {
  margin-bottom: 16px;
  color: #ffffff !important;
}

.cta-subtitle-contact.MuiTypography-root {
  margin-bottom: 16px;
  color: #565a71 !important;
}

.cta-title.MuiTypography-root {
  font-weight: bold;
  line-height: 1.2;
  color: #ffffff !important;
  font-size: 38px;
}

.cta-title-contact.MuiTypography-root {
  font-weight: 600;
  line-height: 1.2;
  color: #3e4154 !important;
  font-size: 36px;
}

.cta-button.MuiButton-root {
  background-color: #008ef7 !important;
  color: #ffffff;
  font-weight: bold;
  padding: 9px 24px;
  text-transform: capitalize;
  border-radius: 8px;
}

.cta-button-contact.MuiButton-root {
  background-color: #0b24c2 !important;
  color: #ffffff;
  font-weight: bold;
  padding: 9px 24px;
  text-transform: capitalize;
  border-radius: 8px;
}

.cardpl {
  padding-left: 0px;
}

.swp-imgcard {
  display: flex;
  align-items: center;
}

.popup-contain {
  font-size: 14px;
}

.date_border fieldset{
  border: 0 !important;
}

/* Small devices (portrait tablets and large phones, 600px and down) */
@media (max-width: 600px) {
  .heroText {
    font-size: 40px !important;
    line-height: 60px !important;
  }

  .startsip {
    height: 45vh !important;
  }

  .handpicked-head {
    font-size: 30px !important;
  }

  .startsip-head {
    font-size: 40px !important;
  }

  .steps-head {
    font-size: 35px !important;
  }

  .testi-head {
    font-size: 36px !important;
  }

  .investhead {
    font-size: 28px !important;
  }

  .whoimg {
    width: 100% !important;
  }

  .heroSectionContainer {
    padding-left: 30px !important;
  }

  .cta-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .cta-title.MuiTypography-root {
    font-size: 34px !important;
    font-weight: 600 !important;
  }

  .cta-container {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .cta-content {
    max-width: 100%;
    margin-bottom: 24px;
    /* margin-left: 0px; */
    margin-left: -15px;
  }

  .wrapper {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .faqbg {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .dashimg {
    width: 100%;
  }

  .swp-card-mar {
    margin-top: 10px;
  }

  .foot-title {
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .link-list a {
    font-size: 12px !important;
  }

  .copyright {
    margin-bottom: 0px !important;
    text-align: start !important;
  }

  .list-container a {
    padding-bottom: 10px;
  }

  .list-container a {
    padding-bottom: 10px !important;
  }

  .occupation-btns {
    padding: 10px !important;
  }

  .texti-slide.css-jc7f8k {
    padding-left: 0px !important;
  }

  .pan-box {
    width: auto !important;
  }

  .bgcolor {
    display: block !important;
    min-height: auto !important;
  }

  .bob-card-actions {
    margin-top: 39px !important;
  }

  .scheme-title {
    font-size: 16px;
  }

  .oder-details-status {
    padding: 11px 10px 0px 10px !important;
  }

  .transaction-label {
    font-size: 12px !important;
  }

  .transaction-value {
    font-size: 12px !important;
  }

  .external-title.MuiTypography-root {
    font-size: 34px !important;
  }

  .card-head-text {
    font-size: 16px !important;
  }

  .pan-topmar {
    margin-top: 20px !important;
  }

  .swp-imgcard {
    display: block;
  }

  .sip-aamount-box {
    padding: 3px !important;
    width: 75px !important;
  }

  .popup-contain {
    font-size: 12px !important;
  }

  .bank-text {
    font-size: 10px !important;
    white-space: nowrap !important;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 8px !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
  border-radius: 8px !important;
}

.swp-card-mar {
  margin-top: 90px;
}

/* Medium devices (landscape tablets, 768px and down) */
@media (max-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and down) */
@media (max-width: 992px) {
  /* Styles go here */
}

/* Extra large devices (large desktops, 1200px and down) */
@media (max-width: 1200px) {
  /* Styles go here */
}
#sidebar {
  background-color: #ffffff; /* Light background */
  width: 330px;
  height: 100vh !important;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e5e5e5; /* Light gray border */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Slight shadow for elegance */
}

.sidebar-header {
  padding: 20px;
  text-align: center;
}

.sidebar-logo {
  max-width: 120px;
}

.components {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-item {
  position: relative;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  color: #585e64; /* Light gray text */
  font-size: 15px;
  width: 100%;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 8px; /* Rounded edges for items */
}

.sidebar-link img {
  width: 20px; /* Icon size */
  margin-right: 15px; /* Spacing between icon and text */
}

.sidebar-link.active {
  width: 100%;
  background-color: #f0f4ff !important; /* Light blue background for active item */
  color: #0a23c1 !important; /* Blue text for active item */
}

.sidebar-link:hover {
  width: 100%;
  background-color: #f8f9fa;
}

.sidebar-label {
  font-size: 15px;
}
.sidebar-label.active {
  color: #0070e7 !important;
}
.Sidebar-icon {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.css-1dyo8e3{
  overflow: visible !important;
}